import React, { useState, useEffect } from "react";
import moment from "moment";

import { Tab, Dialog } from "@headlessui/react";
import {
    CheckIcon,
    CheckCircleIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    ChevronDownIcon,
    ChevronUpIcon,
    XIcon,
    PencilIcon,
    PlusIcon,
    InformationCircleIcon,
    CurrencyDollarIcon,
} from "@heroicons/react/outline";

import { ExclamationCircleIcon } from "@heroicons/react/solid";

//import { InformationCircleIcon } from "@heroicons/react/solid";

import Tooltip from "@/ui/Tooltip";
import { IconButton } from "../ui/Button";
import Select from "../ui/Select";
import { renameStatus } from "@/utils/rename";
import Button from "@/ui/Button";

import { removeExt, PdImage } from "@/utils/image";

import { getCommunityTypes } from "./CommunityUtils";
import { PaidLabel } from "../communityPortal/community/EditUtils";

import { SectionGroup, Section, UnorderedList } from "./CommunityMainInfoOnboarded";

export const formatRoom = (text) => {
    switch (text) {
        case "1 Bedroom":
            return "1 Bed";

        case "1 Bedroom w/den":
            return "1 w/den";

        case "2 Bedroom":
            return "2 Bed";

        case "1 Bathroom":
            return "1 Bath";

        case "1 1/2 Bathroom":
            return "1 1/2 Bath";

        case "2 Bathrooms":
            return "2 Baths";

        default:
            return text;
    }
};

export const formatFloor = (floor) => {
    if (floor == 1) {
        return "1st";
    } else if (floor == 2) {
        return "2nd";
    } else if (floor == 3) {
        return "3rd";
    } else {
        return floor + "th";
    }
};

export const getNumberOfRoom = (community, type) => {
    if (community && community.template && community.template.length > 0) {
        if (type === "All") {
            return community.template.length;
        } else {
            return community.template.filter((t) => t.rooms === type).length;
        }
    }

    return 0;
};

const checkModelAvailabilityText = (template) => {
    let availability = "Not Available";

    const today = moment().format("YYYY-MM-DD");

    if (template.units) {
        template.units.forEach((unit) => {
            if (unit.availability) {
                if (availability === "Not Available") {
                    if (unit.availability === "Available Now") {
                        availability = unit.availability;
                    } else if (unit.availability === "Coming Soon") {
                        if (today >= unit.startDate) {
                            availability = "Available Now";
                        } else {
                            availability = "Coming Soon";
                        }
                    }
                } else if (availability === "Coming Soon") {
                    if (unit.availability === "Available Now") {
                        availability = unit.availability;
                    } else if (unit.availability === "Coming Soon") {
                        if (today >= unit.startDate) {
                            availability = "Available Now";
                        } else {
                            availability = "Coming Soon";
                        }
                    }
                }
            }
        });
    }

    return availability;
};

const checkModelAvailability = (template) => {
    let availability = checkModelAvailabilityText(template);

    if (availability === "Available Now") {
        return (
            <div className="block w-24 py-0.5 text-center bg-green-600 text-white text-sm rounded uppercase">
                {renameStatus(availability)}
            </div>
        );
    } else if (availability === "Coming Soon") {
        return (
            <div className="block w-24 py-0.5 text-center bg-primary-300 text-white text-sm rounded uppercase">
                {renameStatus(availability)}
            </div>
        );
    }

    return null;
};

const sortUnits = (a, b) => {
    if (a.availability === "Available Now" && b.availability === "Available Now") {
        return 0;
    }

    if (a.availability === "Available Now") {
        return -1;
    }

    if (b.availability === "Available Now") {
        return 1;
    }

    if (a.availability === "Coming Soon" && b.availability === "Coming Soon") {
        if (a.startDate < b.startDate) {
            return -1;
        } else if (a.startDate > b.startDate) {
            return 1;
        } else {
            return 0;
        }
    }

    if (a.availability === "Coming Soon") {
        return -1;
    }

    if (b.availability === "Coming Soon") {
        return 1;
    }

    return 0;
};

const sortTemplates = (a, b) => {
    const aAvailability = checkModelAvailabilityText(a);
    const bAvailability = checkModelAvailabilityText(b);

    if (aAvailability === bAvailability) {
        return 0;
    } else if (aAvailability === "Available Now") {
        return -1;
    } else if (bAvailability === "Available Now") {
        return 1;
    } else if (aAvailability === "Coming Soon") {
        return -1;
    } else if (bAvailability === "Coming Soon") {
        return 1;
    } else {
        return 0;
    }
};

const checkModelSpecialOffer = (template) => {
    let specialOffer = false;

    if (template.units) {
        template.units.forEach((unit) => {
            if (unit.specialOffer) {
                specialOffer = true;
            }
        });
    }

    if (specialOffer) {
        return (
            <div className="block w-24 py-0.5 text-center bg-primary-500 text-white text-sm rounded">
                Special Offer
            </div>
        );
    }

    return null;
};

export const checkUnitAvailability = (unit) => {
    let availability = "Not Available";

    const today = moment().format("YYYY-MM-DD");

    if (unit.availability) {
        if (unit.availability === "Available Now") {
            availability = unit.availability;
        } else if (unit.availability === "Coming Soon") {
            if (today >= unit.startDate) {
                availability = "Available Now";
            } else {
                availability = "Coming Soon";
            }
        }
    }

    return availability;
};

// const Availability = ({ availability }) => {
//     if (availability === "Available Now") {
//         return (
//             <div className="block w-24 py-0.5 text-center bg-green-600 text-white whitespace-nowrap text-sm rounded">
//                 {renameStatus(availability)}
//             </div>
//         );
//     } else if (availability === "Coming Soon") {
//         return (
//             <div className="block w-24 py-0.5 text-center bg-primary-300 text-white whitespace-nowrap text-sm rounded">
//                 {renameStatus(availability)}
//             </div>
//         );
//     }

//     return null;
// };

const getBedrooms = (community) => {
    const bedrooms = [
        { value: "All", name: "All (" + getNumberOfRoom(community, "All") + ")" },
        {
            value: "1 Bedroom",
            name: "1 Bed  (" + getNumberOfRoom(community, "1 Bedroom") + ")",
        },
        {
            value: "1 Bedroom w/den",
            name: "1 Bed w/den  (" + getNumberOfRoom(community, "1 Bedroom w/den") + ")",
        },
        {
            value: "2 Bedroom",
            name: "2 Bed  (" + getNumberOfRoom(community, "2 Bedroom") + ")",
        },
        { value: "Studio", name: "Studio  (" + getNumberOfRoom(community, "Studio") + ")" },
    ];

    return bedrooms;
};

const CommunityTemplates = ({
    community,
    onEditTemplate,
    onEditTemplateImages,
    onAddTemplate,
    onEditUnit,
    onAddUnit,
    enableEdit,
}) => {
    return (
        <div>
            <div className="hidden sm:block">
                <CommunityTemplatesDesktop
                    community={community}
                    onEditTemplate={onEditTemplate}
                    onEditTemplateImages={onEditTemplateImages}
                    onAddTemplate={onAddTemplate}
                    onEditUnit={onEditUnit}
                    onAddUnit={onAddUnit}
                    enableEdit={enableEdit}
                />
            </div>

            <div className="sm:hidden">
                <CommunityTemplatesMobile community={community} />
            </div>
        </div>
    );
};

const CommunityTemplatesDesktop = ({
    community,
    onEditTemplate,
    onEditTemplateImages,
    onAddTemplate,
    onEditUnit,
    onAddUnit,
    enableEdit,
}) => {
    const [showAllTemplates, setShowAllTemplates] = useState(false);

    const [bedroom, setBedroom] = useState({
        value: "All",
        name: "All (" + getNumberOfRoom(community, "All") + ")",
    });
    const [templateId, setTemplateId] = useState(
        community.template && community.template.length > 0 ? community.template[0]._id : ""
    );

    let template =
        community.template && community.template.length > 0 && templateId
            ? community.template.find((t) => t._id === templateId)
            : {};

    if (template === null || template === undefined) {
        template = {};
    }

    let numberOfTemplatesToShow = 0;
    if (community.template && community.template.length > 0) {
        numberOfTemplatesToShow = community.template.filter((t) => {
            const availability = checkModelAvailabilityText(t);
            if (availability === "Available Now" || availability === "Coming Soon") {
                return true;
            } else {
                return false;
            }
        }).length;

        if (numberOfTemplatesToShow.length < 2) {
            numberOfTemplatesToShow = 2;
        }
    }

    //console.log("numberOfTemplatesToShow", numberOfTemplatesToShow);

    const bedrooms = getBedrooms(community);

    const paidPlan = community.plan && community.plan.startsWith("Paid");

    if (!paidPlan) {
        return null;
    }

    if (numberOfTemplatesToShow === 0) {
        if (enableEdit) {
            return (
                <div className="flex items-end space-x-4">
                    <Button
                        variant="outlined"
                        className="mt-4 flex items-center"
                        onClick={onAddTemplate}
                    >
                        <PlusIcon className="w-5 h-5 mr-2" />
                        Add Floor Plan
                    </Button>

                    <div>
                        <PaidLabel size="sm" community={community} enableButton={true} />
                        <Button
                            variant="outlined"
                            className="mt-1 flex items-center"
                            onClick={onAddUnit}
                            disabled={!paidPlan}
                        >
                            <PlusIcon className="w-5 h-5 mr-2" />
                            Add Unit
                        </Button>
                    </div>
                </div>
            );
        } else {
            return null;
        }
    }

    return (
        <div className="mt-8">
            <div className="flex items-center justify-between">
                <div className="flex items-center">
                    <h2 className="text-lg sm:text-xl font-bold">
                        Available Units and Pricing
                    </h2>

                    <div className="ml-8 sm:ml-12">
                        <Select
                            label="Bedrooms"
                            options={bedrooms}
                            value={bedroom}
                            onChange={setBedroom}
                            className="w-64"
                        />
                    </div>
                </div>

                {enableEdit ? (
                    <div className="flex items-end space-x-4">
                        <Button
                            variant="outlined"
                            className="flex items-center"
                            onClick={onAddTemplate}
                        >
                            <PlusIcon className="w-5 h-5 mr-2" />
                            Add Floor Plan
                        </Button>

                        <div>
                            <PaidLabel
                                size="sm"
                                community={community}
                                enableButton={true}
                            />

                            <Button
                                variant="outlined"
                                className="mt-1 flex items-center"
                                onClick={onAddUnit}
                                disabled={!paidPlan}
                            >
                                <PlusIcon className="w-5 h-5 mr-2" />
                                Add Unit
                            </Button>
                        </div>
                    </div>
                ) : null}
            </div>

            <div className="mt-4">
                <div className="flex flex-col space-y-8">
                    {community.template &&
                        community.template
                            .sort(sortTemplates)
                            .filter((t) => {
                                return bedroom.value === "All"
                                    ? true
                                    : t.rooms === bedroom.value;
                            })
                            .filter(
                                (t, index) =>
                                    showAllTemplates || index < numberOfTemplatesToShow
                            )
                            .map((t, index) => (
                                <UnitNew
                                    key={t.name + "." + index}
                                    community={community}
                                    template={t}
                                    onEditTemplateImages={onEditTemplateImages}
                                    onEditUnit={onEditUnit}
                                    onEditTemplate={onEditTemplate}
                                    enableEdit={enableEdit}
                                    desktop={true}
                                />
                            ))}
                </div>

                <ShowAll
                    title="Floor Plans"
                    className="pt-4"
                    open={showAllTemplates}
                    onChange={() => setShowAllTemplates((x) => !x)}
                />
            </div>

            <div className="text-sm">
                * Certain restrictions apply. Please contact community for full details.
            </div>

            {enableEdit ? (
                <div className="mt-4 flex items-end space-x-4">
                    <Button
                        variant="outlined"
                        className="mt-4 flex items-center"
                        onClick={onAddTemplate}
                    >
                        <PlusIcon className="w-5 h-5 mr-2" />
                        Add Floor Plan
                    </Button>

                    <div>
                        <PaidLabel size="sm" community={community} enableButton={true} />
                        <Button
                            variant="outlined"
                            className="mt-1 flex items-center"
                            onClick={onAddUnit}
                            disabled={!paidPlan}
                        >
                            <PlusIcon className="w-5 h-5 mr-2" />
                            Add Unit
                        </Button>
                    </div>
                </div>
            ) : null}
        </div>
    );
};

const EditButton = ({ arg, onEdit, className = "" }) => {
    return (
        <Tooltip title="Edit">
            <IconButton
                onClick={() => onEdit(arg)}
                className={className}
                data-tippy-content="Tooltip placement top"
                data-tippy-placement="top"
            >
                <PencilIcon className="w-4 h-4 text-primary-500" />
            </IconButton>
        </Tooltip>
    );
};

const CommunityTemplatesMobile = ({ community }) => {
    const [bedroom, setBedroom] = useState({
        value: "All",
        name: "All (" + getNumberOfRoom(community, "All") + ")",
    });
    const [templateId, setTemplateId] = useState("");

    let template =
        community.template && community.template.length > 0 && templateId
            ? community.template.find((t) => t._id === templateId)
            : {};

    if (template === null || template === undefined) {
        template = {};
    }

    const handleSelectTemplate = (t) => {
        if (t.name === template.name) {
            setTemplateId("");
        } else {
            setTemplateId(t._id);
        }
    };

    let selectedFloorPlanImg = {};
    let otherImages = [];

    if (template.media && template.media.length > 0) {
        const floorPlan = template.media.find((m) => m.type === "Floor Plan");

        if (floorPlan) {
            selectedFloorPlanImg = floorPlan;
        }

        otherImages = template.media.filter((m) => m.type === "Unit");
    }

    let numberOfTemplatesToShow = 0;
    if (community.template && community.template.length > 0) {
        numberOfTemplatesToShow = community.template.filter((t) => {
            const availability = checkModelAvailabilityText(t);
            if (availability === "Available Now" || availability === "Coming Soon") {
                return true;
            } else {
                return false;
            }
        }).length;

        if (numberOfTemplatesToShow.length < 2) {
            numberOfTemplatesToShow = 2;
        }
    }

    console.log("numberOfTemplatesToShow", numberOfTemplatesToShow);

    const bedrooms = getBedrooms(community);

    const paidPlan = community.plan && community.plan.startsWith("Paid");

    if (!paidPlan) {
        return null;
    }

    if (numberOfTemplatesToShow === 0) {
        return null;
    }

    return (
        <>
            <div className="mt-8">
                <div className="flex items-center">
                    <h2 className="text-base font-bold">Available Units and Pricing</h2>
                </div>

                <div className="mt-4 flex justify-between space-x-4">
                    <div className="w-full sm:w-72 sm:h-[580px] flex-shrink-0 bg-gray-100 rounded">
                        <div className="sm:h-[580px] py-2 overflow-y-auto">
                            {community.template &&
                                community.template
                                    .filter((t) => {
                                        return bedroom.value === "All"
                                            ? true
                                            : t.rooms === bedroom.value;
                                    })
                                    .map((t, index) => {
                                        const selected = t.name === template.name;
                                        return (
                                            <div key={t.name}>
                                                <button
                                                    onClick={() => handleSelectTemplate(t)}
                                                    className={`p-4 py-2 w-full flex justify-between items-center text-gray-800 
                                        ${index > 0 ? "border-t border-gray-300" : ""}`}
                                                >
                                                    <div>
                                                        <div className="text-left">
                                                            {t.name}
                                                        </div>
                                                        <div
                                                            className={`flex items-center font-light text-sm`}
                                                        >
                                                            <span>
                                                                {formatRoom(t.rooms)}
                                                            </span>
                                                            <span className="px-2">
                                                                &bull;
                                                            </span>
                                                            <span>
                                                                {formatRoom(t.bathrooms)}
                                                            </span>
                                                            <span className="px-2">
                                                                &bull;
                                                            </span>
                                                            <span>
                                                                {t.areaSqFeet} sq ft
                                                            </span>
                                                        </div>
                                                        <div className="flex space-x-2">
                                                            {checkModelAvailability(t)}
                                                            {checkModelSpecialOffer(t)}
                                                        </div>
                                                    </div>

                                                    <ChevronRightIcon className="ml-0 w-4 h-4" />
                                                </button>
                                            </div>
                                        );
                                    })}
                        </div>
                    </div>
                </div>
            </div>

            <div className="text-sm">
                * Certain restrictions apply. Please contact community for full details.
            </div>

            <Dialog
                open={Boolean(templateId)}
                onClose={() => setTemplateId("")}
                className="fixed z-40 top-[54px] bottom-6 left-2 right-2 overflow-y-auto"
            >
                <Dialog.Overlay className="fixed -z-10 inset-0 bg-black opacity-60" />

                <div className="z-50 bg-gray-100 w-full h-full border-2 border-gray-500 rounded-xl">
                    <div className="px-2 flex flex-col justify-between">
                        <div className="h-12 border-b border-gray-300">
                            <div className="px-6 py-1 flex justify-between items-center">
                                <div className="flex space-x-6">{template.name}</div>
                                <div>
                                    <IconButton onClick={() => setTemplateId("")}>
                                        <XIcon className="w-6 h-6" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>

                        {/*<Unit
                            community={community}
                            template={template}
                            selectedFloorPlanImg={selectedFloorPlanImg}
                            otherImages={otherImages}
                                />*/}

                        <UnitNew community={community} template={template} mobile={true} />
                    </div>
                </div>
            </Dialog>
        </>
    );
};

const UnitNew = ({
    community,
    template,
    onEditTemplateImages,
    onEditUnit,
    onEditTemplate,
    enableEdit,
    desktop = false,
    mobile = false,
}) => {
    const [openImages, setOpenImages] = useState(-1);
    const [showAllUnits, setShowAllUnits] = useState(false);
    const [openUnitId, setOpenUnitId] = useState(null);

    const handleGetDatasheet = () => {
        window.open(
            "/community-mls/" + community.nameId + "/floor-plan/" + template._id,
            "_blank"
        );
    };

    const handleSeeAllPhotos = (_index) => {
        setOpenImages(_index);
    };

    const handleClickUnit = (unit) => {
        setOpenUnitId((x) => (x === unit._id ? null : unit._id));
    };

    let selectedFloorPlanImg = {};
    let otherImages = [];

    if (template.media && template.media.length > 0) {
        const floorPlan = template.media.find((m) => m.type === "Floor Plan");

        if (floorPlan) {
            selectedFloorPlanImg = floorPlan;
        }

        otherImages = template.media.filter((m) => m.type === "Unit");
    }

    let numberOfAvailableorComingSoonUnits = 0;
    if (template.units && template.units.length > 0) {
        numberOfAvailableorComingSoonUnits = template.units.filter(
            (unit) =>
                unit.availability === "Available Now" || unit.availability === "Coming Soon"
        ).length;
    }

    const paidPlan = community.plan && community.plan.startsWith("Paid");

    return (
        <div className="h-full">
            <UnitImagesDialog
                open={openImages >= 0}
                imageIndex={openImages}
                onClose={() => setOpenImages(-1)}
                community={community}
                template={template}
                selectedFloorPlanImg={selectedFloorPlanImg}
                otherImages={otherImages}
                enableEdit={enableEdit}
                onEditTemplateImages={onEditTemplateImages}
            />

            <div className="sm:px-8 py-4 bg-gray-50 rounded border border-gray-300">
                <div className="flex flex-col sm:flex-row items-center justify-between space-y-4 sm:space-y-0">
                    <div className="flex flex-col sm:flex-row  items-center space-x-4">
                        <h3 className="mb-4 sm:mb-0 text-lg font-black">{template.name}</h3>

                        {checkModelAvailability(template)}

                        {enableEdit ? (
                            <EditButton onEdit={onEditTemplate} arg={template} />
                        ) : null}
                    </div>
                    <Button size="xs" onClick={handleGetDatasheet}>
                        View Floor Plan PDF Listing
                    </Button>
                </div>

                <div className="mt-4 sm:mt-0 text-base text-center sm:text-left">
                    <span>{formatRoom(template.rooms)}</span>
                    <span className="px-2">&bull;</span>
                    <span>{formatRoom(template.bathrooms)}</span>
                    {template.areaSqFeet && template.areaSqFeet > 0 ? (
                        <>
                            <span className="px-2">&bull;</span>
                            <span>{template.areaSqFeet} sq ft</span>
                        </>
                    ) : null}
                </div>

                <div className="px-4 sm:px-0 mt-4 grid grid-cols-1 sm:grid-cols-4 sm:space-x-4 space-y-4 sm:space-y-0">
                    {selectedFloorPlanImg.url || (otherImages && otherImages.length > 0) ? (
                        <div className="h-full  bg-white rounded border border-gray-300">
                            <div className="px-4 py-1 flex justify-between items-center border-b border-gray-300">
                                <span className="font-semibold">Floor Plan</span>
                                {/*<button
                                    className="text-primary-500 underline hover:font-bold"
                                    onClick={() => handleSeeAllPhotos(0)}
                                >
                                    See All
                                </button>*/}
                            </div>
                            <div className="px-1 py-1 flex justify-center items-center">
                                <div>
                                    <button onClick={() => handleSeeAllPhotos(0)}>
                                        <PdImage
                                            src={
                                                selectedFloorPlanImg.url
                                                    ? removeExt(selectedFloorPlanImg.url) +
                                                      ".sm.jpg"
                                                    : removeExt(otherImages[0].url) +
                                                      ".sm.jpg"
                                            }
                                            originalSrc={
                                                selectedFloorPlanImg.url
                                                    ? selectedFloorPlanImg.url
                                                    : otherImages[0].url
                                            }
                                            alt={
                                                selectedFloorPlanImg.title
                                                    ? selectedFloorPlanImg.title
                                                    : otherImages[0].title
                                            }
                                            className="object-cover object-center"
                                            style={{ maxWidth: "100%", maxHeight: 200 }}
                                        />
                                        {/*<img
                                                src={
                                                    selectedFloorPlanImg.url
                                                        ? selectedFloorPlanImg.url
                                                        : otherImages[0].url
                                                }
                                                alt={
                                                    selectedFloorPlanImg.title
                                                        ? selectedFloorPlanImg.title
                                                        : otherImages[0].title
                                                }
                                                style={{ maxWidth: "100%", maxHeight: 200 }}
                                        />*/}
                                    </button>

                                    <div className="flex justify-center items-center">
                                        <div className="px-2 text-sm">
                                            {selectedFloorPlanImg.description}
                                        </div>
                                        {enableEdit ? (
                                            <EditButton
                                                onEdit={onEditTemplateImages}
                                                arg={template}
                                            />
                                        ) : null}
                                    </div>
                                </div>
                            </div>
                        </div>
                    ) : (
                        <div>Floor Plan Image Not Available</div>
                    )}

                    <Info template={template} />
                    <KitchenInfo template={template} />
                    <BathInfo template={template} />
                </div>

                <div className="mt-4">
                    <TemplateImages
                        images={otherImages}
                        onOpenAllPhotos={handleSeeAllPhotos}
                    />
                </div>

                {numberOfAvailableorComingSoonUnits > 0 ? (
                    <div>
                        <div className="mt-8 px-4 sm:px-2 py-2 flex items-center space-x-2 sm:space-x-6 text-sm uppercase font-bold border-b border-gray-300">
                            <div className="w-20 shrink-0">Unit</div>
                            <div className="w-28 shrink-0">Monthly Base Rate</div>
                            <div className="w-28 shrink-0">Availability</div>
                            {desktop ? <div className="grow">Information</div> : null}
                        </div>

                        {template.units
                            .sort(sortUnits)
                            .filter(
                                (unit) =>
                                    unit.availability === "Available Now" ||
                                    unit.availability === "Coming Soon"
                            )
                            .map((unit) => (
                                <div key={unit.name} className="border-b border-gray-300">
                                    <button
                                        onClick={() => handleClickUnit(unit)}
                                        className="w-full text-left px-4 sm:px-2 py-1.5 flex items-start space-x-2 sm:space-x-6 hover:bg-gray-200/75"
                                    >
                                        <div className="w-20 shrink-0 text-primary-500 font-bold">
                                            {unit.name}
                                        </div>
                                        <div className="w-28 shrink-0 font-bold">
                                            {unit.monthlyRate ? "$" + unit.monthlyRate : ""}
                                        </div>
                                        <div className="w-28 shrink-0">
                                            {checkUnitAvailability(unit) === "Available Now"
                                                ? "Now"
                                                : checkUnitAvailability(unit) ===
                                                  "Coming Soon"
                                                ? moment(unit.startDate).format(
                                                      "MMMM D, YYYY"
                                                  )
                                                : ""}
                                        </div>

                                        <div className="grow flex flex-col">
                                            {desktop && paidPlan && unit.specialOffer ? (
                                                <div className="text-primary-500 font-bold">
                                                    Special Incentive: {unit.specialOffer}*
                                                </div>
                                            ) : null}

                                            {desktop && paidPlan && unit.description ? (
                                                <div className="">{unit.description}</div>
                                            ) : null}
                                        </div>

                                        {desktop ? (
                                            <div>
                                                {openUnitId === unit._id ? (
                                                    <ChevronUpIcon className="w-5 h-5" />
                                                ) : (
                                                    <ChevronDownIcon className="w-5 h-5" />
                                                )}
                                            </div>
                                        ) : null}
                                    </button>

                                    {openUnitId === unit._id || mobile ? (
                                        <div className="mt-2 mb-6 px-4 sm:px-0">
                                            <div className="grow flex flex-col">
                                                {mobile && paidPlan && unit.specialOffer ? (
                                                    <div className="text-primary-500 font-bold">
                                                        Special Incentive:{" "}
                                                        {unit.specialOffer}*
                                                    </div>
                                                ) : null}

                                                {mobile && paidPlan && unit.description ? (
                                                    <div className="">
                                                        {unit.description}
                                                    </div>
                                                ) : null}
                                            </div>

                                            <UnitMoreInfo
                                                community={community}
                                                unit={unit}
                                                enableEdit={enableEdit}
                                                onEditUnit={onEditUnit}
                                            />
                                        </div>
                                    ) : null}
                                </div>
                            ))}
                    </div>
                ) : (
                    <div className="px-4 sm:px-0 mt-2 font-semibold">
                        {paidPlan ? "No Available Units" : ""}
                    </div>
                )}
            </div>
        </div>
    );
};

const UnitMoreInfo = ({ community, unit, enableEdit, onEditUnit }) => {
    let descriptors = unit.descriptors;

    if (unit.customDescriptors) {
        if (descriptors) {
            descriptors += ";";
        } else {
            descriptors = "";
        }

        descriptors += unit.customDescriptors;
    }

    const paidPlan = community.plan && community.plan.startsWith("Paid");

    return (
        <div className="flex justify-between items-start">
            <div>
                {unit.floor ? (
                    <div>Located on the {formatFloor(unit.floor)} Floor</div>
                ) : null}

                {descriptors ? (
                    <div className="mt-1 sm:flex sm:gap-1 sm:flex-wrap">
                        {descriptors.split(";").map((descriptor, index) => (
                            <div
                                key={descriptor}
                                className="flex items-center text-sm text-gray-800"
                            >
                                <span>
                                    <CheckCircleIcon className="w-4 h-4 text-primary-500" />
                                </span>
                                <span className="ml-1 whitespace-nowrap">{descriptor}</span>
                            </div>
                        ))}
                    </div>
                ) : null}

                {unit.typeOfCare ? (
                    <div className="mt-3">
                        <p className="text-sm">This unit supports:</p>
                        <div className="text-primary-500 text-sm uppercase">
                            {getCommunityTypes(unit.typeOfCare, "&bull;")}
                        </div>
                    </div>
                ) : null}

                {paidPlan ? (
                    <div className="mt-2">
                        {unit.depositsAndFeesInfo ? (
                            <SectionGroup
                                title="Unit Deposits And Fees (Deposits and fees may differ on individual units)"
                                cols="grid-cols-2 sm:grid-cols-3"
                                border="border-t border-r border-l rounded-t"
                                enableEdit={false}
                                onEdit={() => {}}
                                step={4}
                            >
                                <Section title="" className="sm:col-span-3">
                                    <div>
                                        <p className="px-4 prose prose-sm whitespace-pre-wrap">
                                            {unit.depositsAndFeesInfo}
                                        </p>
                                    </div>
                                </Section>
                            </SectionGroup>
                        ) : null}

                        <SectionGroup
                            title="Community Deposits and Fees* (Deposits and fees may differ on individual units)"
                            cols="grid-cols-2 sm:grid-cols-3"
                            border="border-t border-l border-r border-b"
                            enableEdit={false}
                            onEdit={() => {}}
                            step={4}
                        >
                            <Section
                                title="Community Deposits and Fees"
                                className="sm:col-span-2"
                            >
                                <UnorderedList>
                                    <li>{community.deposit}</li>
                                    <li>{community.communityFee}</li>
                                    <li>{community.careFee}</li>
                                    <li>{community.secondPersonFee}</li>
                                    <li>{community.parkingFee}</li>
                                </UnorderedList>
                            </Section>

                            <Section title="Pet Deposit and Fee">
                                <UnorderedList>
                                    <li>{community.petDeposit}</li>
                                    <li>{community.monthlyPetFee}</li>
                                </UnorderedList>
                            </Section>

                            {community.depositsAndFeesInfo ? (
                                <Section
                                    title="Additional Information for Community Deposits And Fees"
                                    className="sm:col-span-3"
                                >
                                    <div>
                                        <p className="px-4 prose prose-sm whitespace-pre-wrap">
                                            {community.depositsAndFeesInfo}
                                        </p>
                                    </div>
                                </Section>
                            ) : null}
                        </SectionGroup>
                    </div>
                ) : null}
            </div>

            {enableEdit ? <EditButton onEdit={onEditUnit} arg={unit} /> : null}
        </div>
    );
};

const OpenClose = ({ children }) => {
    const [open, setOpen] = useState(false);

    if (React.Children.toArray(children).length === 0) {
        return null;
    }

    return (
        <div>
            <button
                className="mt-1 flex items-center pl-2 pr-3 py-0.5 text-sm font-bold border border-gray-500 rounded hover:text-white hover:bg-primary-500"
                onClick={() => setOpen((x) => !x)}
            >
                {!open ? (
                    <ChevronRightIcon className="w-4 h-4" />
                ) : (
                    <ChevronDownIcon className="w-4 h-4" />
                )}
                <span>{open ? "Show Less" : "Show More"}</span>
            </button>

            {open ? children : null}
        </div>
    );
};

const ShowAll = ({ open, onChange, title, className }) => {
    return (
        <div className={className}>
            <button
                className="flex items-center pl-2 pr-3 py-1 text-base font-bold border-2 border-primary-500 rounded hover:text-white hover:bg-primary-500"
                onClick={onChange}
            >
                {!open ? (
                    <ChevronRightIcon className="w-4 h-4" />
                ) : (
                    <ChevronDownIcon className="w-4 h-4" />
                )}
                <span>{open ? "Show Less " + title : "Show All " + title}</span>
            </button>
        </div>
    );
};

const Info = ({ template }) => {
    return (
        <div className="border rounded border-gray-300 bg-white">
            <h2 className="px-4 py-1 font-semibold  border-b border-gray-300">Info</h2>

            <div className="grid grid-cols-1 ">
                <ul className="px-4 py-1 grid grid-cols-1 text-sm text-gray-600">
                    {template.roomType ? <li>{template.roomType}</li> : null}
                    {template.livingRoom ? <li>Living Room</li> : null}
                    {template.other ? <li>{template.other}</li> : null}
                    {template.washerDryer ? (
                        <li>{template.washerDryer} Washer/Dryer</li>
                    ) : null}
                    {template.adaHearing ? <li>ADA Hearing Impairer</li> : null}
                    {template.adaWheelchair ? <li>ADA Wheelchair</li> : null}
                </ul>
            </div>
        </div>
    );
};

const KitchenInfo = ({ template }) => {
    if (template.kitchenType && template.kitchenType !== "None") {
        return (
            <div className="border rounded border-gray-300 bg-white">
                <h2 className="px-4 py-1 font-semibold border-b border-gray-300">
                    Kitchen
                </h2>

                <div className="grid grid-cols-1">
                    <ul className="px-4 py-1 grid grid-cols-1 text-sm text-gray-600">
                        <li>{template.kitchenType}</li>
                        {template.kitchenOven ? <li>Oven</li> : null}
                        {template.kitchenMicrowave &&
                        template.kitchenMicrowave !== "None" ? (
                            <li>{template.kitchenMicrowave} Microwave</li>
                        ) : null}

                        {template.kitchenCooktop ? (
                            <li>{template.kitchenCooktop} Cook top burners</li>
                        ) : null}

                        {template.kitchenDishwasher ? <li>Dishwasher</li> : null}
                        {template.kitchenDisposal ? <li>Disposal</li> : null}
                        {template.kitchenFridgeSize ? (
                            <li>
                                {template.kitchenFridgeSize} Refrigerator,{" "}
                                {template.kitchenFridgeOptions
                                    ? template.kitchenFridgeOptions.replaceAll(";", ", ")
                                    : ""}
                            </li>
                        ) : null}

                        {template.kitchenSink ? (
                            <li>{template.kitchenSink} Kitchen Sink</li>
                        ) : null}
                    </ul>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const BathInfo = ({ template }) => {
    return (
        <div className="border border-gray-300 rounded bg-white">
            <h2 className="px-4 py-1 font-semibold  border-b border-gray-300">Bath</h2>

            <div className="grid grid-cols-1">
                <ul className="px-4 py-1 grid grid-cols-1 text-sm text-gray-600">
                    <li>{template.bathrooms}</li>

                    {template.bathType
                        ? template.bathType
                              .split(";")
                              .map((type) => <li key={type}>{type}</li>)
                        : null}

                    {template.bathTags
                        ? template.bathTags
                              .split(";")
                              .map((type) => <li key={type}>{type}</li>)
                        : null}
                    {template.sink ? <li>{template.sink} Sink</li> : null}
                </ul>
            </div>
        </div>
    );
};

const TemplateImages = ({ images, onOpenAllPhotos }) => {
    if (!images || images.length === 0) return null;

    return (
        <div className="flex space-x-4">
            {images
                .filter((im, i) => i < 3)
                .map((image, index) => (
                    <button
                        key={index}
                        className="relative"
                        onClick={() => onOpenAllPhotos(index + 1)}
                    >
                        <PdImage
                            src={removeExt(image.url) + ".sm.jpg"}
                            originalSrc={image.url}
                            alt={image.alt}
                            className="object-cover object-center w-full h-40"
                            style={{ maxHeight: 160 }}
                        />
                        {/*<img
                            src={image.url}
                            alt={image.alt}
                            className="w-full h-40 object-cover"
                        />*/}
                    </button>
                ))}
            <button
                className="text-primary-500 underline hover:font-bold"
                onClick={() => onOpenAllPhotos(1)}
            >
                See All {images.length} Photos
            </button>
        </div>
    );
};

const UnitImagesDialog = ({
    open,
    imageIndex: initialImageIndex,
    onClose,
    community,
    template,
    selectedFloorPlanImg,
    otherImages,
    enableEdit,
    onEditTemplateImages,
}) => {
    const [imageIndex, setImageIndex] = useState(initialImageIndex);

    useEffect(() => {
        setImageIndex(initialImageIndex);
    }, [initialImageIndex]);

    const handleLeft = () => {
        let index = imageIndex - 1;
        if (index >= 0) {
            setImageIndex(index);
        }
    };

    const handleRight = () => {
        if (template.media && template.media.length > 0) {
            let index = imageIndex + 1;
            if (index <= template.media.length - 1) {
                setImageIndex(index);
            }
        }
    };

    let images = [];
    if (selectedFloorPlanImg) {
        images.push(selectedFloorPlanImg);
    }

    if (otherImages) {
        images = [...images, ...otherImages];
    }

    //console.log("images", images, imageIndex);

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="fixed z-40 top-16 bottom-12 left-6 right-6 sm:top-32 sm:bottom-32 sm:left-32 sm:right-32 overflow-y-auto"
        >
            <Dialog.Overlay className="fixed -z-10 inset-0 bg-black opacity-60" />

            <div className="z-50 bg-white w-full h-full border-2 border-gray-500 rounded-xl">
                <div className="h-full px-2 flex flex-col justify-between">
                    <div className="h-12 border-b border-gray-300">
                        <div className="px-6 py-1 flex justify-between items-center">
                            <div>{template.name} - Floor Plan and Photos</div>
                            <div>
                                <IconButton onClick={onClose}>
                                    <XIcon className="w-6 h-6" />
                                </IconButton>
                            </div>
                        </div>
                    </div>

                    <div className="mt-8 px-4 h-full flex justify-between space-x-8">
                        <div className="flex items-center">
                            <div className="bg-gray-200 rounded-full">
                                <IconButton onClick={handleLeft}>
                                    <ChevronLeftIcon className="w-6 h-6" />
                                </IconButton>
                            </div>
                        </div>

                        {imageIndex >= 0 ? (
                            <div className="h-full flex flex-col justify-start items-center">
                                <PdImage
                                    src={removeExt(images[imageIndex].url)}
                                    originalSrc={images[imageIndex].url}
                                    alt={images[imageIndex].title}
                                    className="object-cover object-center max-w-[calc(100vw-32rem)] max-h-[calc(100vh-30rem)]"
                                    style={{ maxHeight: 160 }}
                                />
                                {/*<img
                                    src={images[imageIndex].url}
                                    alt={images[imageIndex].title}
                                    className="max-w-[calc(100vw-32rem)] max-h-[calc(100vh-30rem)] object-cover"
                                />*/}

                                <div className="mt-4 flex justify-center items-center">
                                    {images[imageIndex].description}

                                    {enableEdit ? (
                                        <EditButton
                                            onEdit={onEditTemplateImages}
                                            arg={template}
                                        />
                                    ) : null}
                                </div>
                            </div>
                        ) : null}

                        <div className="flex items-center">
                            <div className="bg-gray-200 rounded-full">
                                <IconButton onClick={handleRight}>
                                    <ChevronRightIcon className="w-6 h-6" />
                                </IconButton>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Dialog>
    );
};

export default CommunityTemplates;
