import React, { useState, useEffect, useContext } from "react";
import Image from "next/legacy/image";
import Link from "@/utils/link";

//import ReactMarkdown from "react-markdown";

import {
    ChevronDownIcon,
    ChevronUpIcon,
    PencilIcon,
    QuestionMarkCircleIcon,
    DocumentTextIcon,
    ArrowSmLeftIcon,
} from "@heroicons/react/outline";

import { IconButton } from "../ui/Button";
import Button from "@/ui/Button";
import Tooltip from "@/ui/Tooltip";
import { imageLoader, removeExt, PdImage } from "@/utils/image";

import { LayoutContext } from "../common/LayoutContext";
import { useWindowScrollPosition, useWindowSize } from "../../utils/hooks";
import CommunityTemplates from "./CommunityTemplates";
import CommunityDialogGalleryOnboarded from "./CommunityDialogGalleryOnboarded";
import { FavoriteButton } from "./CommunityActions";

import { TourRequestDialog } from "../dialogs/TourRequest";
import UserVisitCommunity from "../dialogs/UserVisitCommunity";

import {
    ContactCommunityCard,
    BookYourTourCard,
    AskQuestionCard,
    CommunitySocialButtons,
    RequestInfoCard,
} from "./CTACards";

import RsvpRequest from "../dialogs/RsvpRequest";

import SeoLinks from "@/components/home/SeoLinks";
import HomeFooter from "@/components/home/HomeFooter";
import CommunityActions from "./CommunityActions";

import {
    getCommunityTypes,
    CommunityTypesDialog,
    CommunityLicenseNumberDialog,
    formatPhoneNumber,
} from "./CommunityUtils";

// Move this to it's own component, so it will not cause a re-render when scrolling
const ScrollHeader = ({ children }) => {
    const scrollY = useWindowScrollPosition();

    return (
        <div
            className={`hidden sm:block py-1.5 ${
                scrollY > 10
                    ? "shadow-lg bg-primary-500/95 text-white"
                    : "bg-gray-50/95 text-black"
            }`}
        >
            {children}
        </div>
    );
};

const CommunityMainInfoOnboarded = ({
    community,
    preview = false,
    enableEdit = false,
    onEdit,
    onEditTemplate,
    onEditTemplateImages,
    onAddTemplate,
    onEditUnit,
    onAddUnit,
    sidebar = false,
    onClose,
}) => {
    const [readMore, setReadMore] = useState(false);
    const address = community.contact[0].address[0];
    const [openCommunityTypesDialog, setOpenCommunityTypesDialog] = useState(false);
    const [openCommunityLicenseNumberDialog, setOpenCommunityLicenseNumberDialog] =
        useState(false);
    const [openGallery, setOpenGallery] = useState(-1);
    const [openTourRequestDialog, setOpenTourRequestDialog] = useState(false);

    let phone = "";
    if (community.contact[0].phone && community.contact[0].phone.length > 0) {
        phone = community.contact[0].phone[0].number;
    }

    let amenities = [];

    if (community.amenities) {
        amenities = [...amenities, ...community.amenities.split(";")];
    }

    if (community.amenitiesTags) {
        amenities = [...amenities, ...community.amenitiesTags.split(";")];
    }

    let activities = [];

    if (community.activities) {
        activities = [...activities, ...community.activities.split(";")];
    }

    if (community.activitiesTags) {
        activities = [...activities, ...community.activitiesTags.split(";")];
    }

    let dining = [];

    if (community.dining) {
        dining = [...dining, ...community.dining.split(";")];
    }

    if (community.diningTags) {
        dining = [...dining, ...community.diningTags.split(";")];
    }

    let care = [];

    if (community.careOptions) {
        care = [...care, ...community.careOptions.split(";")];
    }

    if (community.careTags) {
        care = [...care, ...community.careTags.split(";")];
    }

    let careGiver = [];
    if (community.careNurse) {
        careGiver = [...careGiver, ...community.careNurse.split(";")];
    }

    if (community.careGiverTags) {
        careGiver = [...careGiver, ...community.careGiverTags.split(";")];
    }

    const paidPlan = community.plan && community.plan.startsWith("Paid");

    let logoImage = null;
    let activitiesImages = [];
    let amenitiesImages = [];
    let diningImages = [];
    if (community.media) {
        const media = community.media.filter(
            (m) => m.mediaType !== "Video" && m.mediaType !== "Document"
        );

        if (paidPlan) {
            logoImage = media.find((m) => m.type === "Community Logo");
        }

        activitiesImages = media.filter((m) => m.type === "Activity");
        amenitiesImages = media.filter((m) => m.type === "Amenity");
        diningImages = media.filter((m) => m.type === "Dinning" || m.type === "Dining");
    }

    const floorPlansConfigured = community.templates ? community.templates.length : 0;

    return (
        <div className="mt-2">
            {!sidebar ? (
                <div className="z-40 fixed top-16 left-0 right-0 w-full h-12">
                    <ScrollHeader>
                        <div className="container mx-auto px-4 sm:px-16">
                            <div className="mt-1 mr-2 flex items-center justify-between">
                                <div className="flex space-x-3 text-sm">
                                    <button
                                        className="py-1 flex items-center hover:underline font-bold"
                                        onClick={onClose}
                                    >
                                        <ArrowSmLeftIcon className="mr-1 w-6 h-6" />
                                        <span>Search</span>
                                    </button>

                                    <ScrollButton title="Overview" />
                                    <ScrollButton title="Units/Pricing" />
                                    <ScrollButton title="Deposits/Fees" />
                                    <ScrollButton title="Community Details" />
                                    <ScrollButton title="Care Info" />
                                    <ScrollButton title="Food/Dining" />
                                    <ScrollButton title="Amenities" />
                                    <ScrollButton title="Activities" />

                                    <button
                                        className="font-bold hover:underline"
                                        onClick={() => setOpenTourRequestDialog(true)}
                                    >
                                        Book Tour
                                    </button>
                                </div>

                                <CommunityActions community={community} dark={true} />
                            </div>
                        </div>
                    </ScrollHeader>

                    <TourRequestDialog
                        community={community}
                        open={openTourRequestDialog}
                        setOpen={setOpenTourRequestDialog}
                    />
                </div>
            ) : null}

            <div className="sm:grid sm:grid-cols-3 gap-x-8">
                <div className="sm:col-span-2">
                    <div className="flex items-center space-x-8">
                        <div>
                            <div className="flex items-center">
                                <h1 className="text-xl text-gray-900 font-black">
                                    {community.name}
                                </h1>
                                {enableEdit ? (
                                    <EditButton step={1} onEdit={onEdit} />
                                ) : null}
                            </div>
                            <div className="flex items-start justify-between">
                                <div>
                                    {address ? (
                                        <h2 className="text-base text-black">
                                            {address.street}, <span>{address.city}</span>,{" "}
                                            <span>{address.state}</span>{" "}
                                            {address.postalCode}
                                        </h2>
                                    ) : null}

                                    <div className="flex space-x-8">
                                        {phone ? (
                                            <div className="font-black">
                                                {formatPhoneNumber(phone)}
                                            </div>
                                        ) : null}

                                        {community.webSite ? (
                                            <a
                                                className="text-primary-500 underline"
                                                href={community.webSite}
                                            >
                                                Community Website
                                            </a>
                                        ) : null}
                                    </div>

                                    {community.licenseNumber ||
                                    community.licenseNumber2 ||
                                    community.licenseNumber3 ? (
                                        <div className="flex items-center text-gray-800 space-x-2">
                                            {community.licenseNumber ? (
                                                <div>
                                                    State License:{" "}
                                                    <Link
                                                        href={
                                                            "/resources/licensing-by-state"
                                                        }
                                                    >
                                                        <a className="text-primary-500 underline">
                                                            {community.licenseNumber}
                                                        </a>
                                                    </Link>
                                                    {community.licenseNumber2 ? (
                                                        <span>, </span>
                                                    ) : null}
                                                </div>
                                            ) : null}

                                            {community.licenseNumber2 ? (
                                                <div>
                                                    Federal License:{" "}
                                                    <span className="text-black">
                                                        {community.licenseNumber2}
                                                    </span>
                                                </div>
                                            ) : null}

                                            <IconButton
                                                onClick={() =>
                                                    setOpenCommunityLicenseNumberDialog(
                                                        true
                                                    )
                                                }
                                            >
                                                <QuestionMarkCircleIcon className="w-5 h-5" />
                                            </IconButton>
                                        </div>
                                    ) : null}
                                </div>
                            </div>
                        </div>
                        <div className="">
                            <FavoriteButton community={community} />
                        </div>
                    </div>

                    <div className="flex items-center">
                        <div className="mt-4 flex space-x-4 text-md font-semibold text-gray-900">
                            <div className="flex items-center space-x-0 flex-wrap uppercase text-primary-500">
                                {getCommunityTypes(community.facilityType, "|", 1000)}
                                <IconButton
                                    onClick={() => setOpenCommunityTypesDialog(true)}
                                >
                                    <QuestionMarkCircleIcon className="w-5 h-5" />
                                </IconButton>
                            </div>
                        </div>

                        {enableEdit ? (
                            <EditButton step={4} onEdit={onEdit} className="ml-2" />
                        ) : null}
                    </div>

                    <CommunityTypesDialog
                        open={openCommunityTypesDialog}
                        onClose={() => setOpenCommunityTypesDialog(false)}
                    />

                    <CommunityLicenseNumberDialog
                        open={openCommunityLicenseNumberDialog}
                        onClose={() => setOpenCommunityLicenseNumberDialog(false)}
                    />

                    {paidPlan && community.specialIncentive ? (
                        <div className="mt-4">
                            <div className="flex items-center space-x-4">
                                <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400">
                                    Special Incentive
                                </div>
                                <div className="text-base font-bold">
                                    {community.specialIncentive}
                                </div>
                            </div>
                        </div>
                    ) : null}

                    {paidPlan && (community.upcomingEvent || community.upcomingEventUrl) ? (
                        <div className="mt-4 sm:flex sm:items-center sm:space-x-4">
                            <div className="flex items-center space-x-4">
                                <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400 text-center text-nowrap">
                                    Upcoming Event
                                </div>

                                <div className="text-base font-bold">
                                    {community.upcomingEvent}
                                </div>
                            </div>

                            <div className="mt-4 sm:mt-0 flex items-center space-x-4">
                                {community.upcomingEventUrl ? (
                                    <ViewDocument
                                        href={community.upcomingEventUrl}
                                        title="View Upcoming Event"
                                        className="w-48"
                                    />
                                ) : null}

                                <RsvpRequest community={community} />
                            </div>
                        </div>
                    ) : null}

                    {paidPlan && (community.supportGroups || community.supportGroupsUrl) ? (
                        <div className="mt-4 sm:flex sm:items-center sm:space-x-4">
                            <div className="flex items-center space-x-4">
                                <div className="px-2 py-0.5 text-xs uppercase font-black text-white rounded-full bg-primary-400 text-center text-nowrap">
                                    Support Groups
                                </div>

                                <div className="text-base font-bold">
                                    {community.supportGroups}
                                </div>
                            </div>

                            <div className="mt-4 sm:mt-0 flex items-center space-x-4">
                                {community.supportGroupsUrl ? (
                                    <ViewDocument
                                        href={community.supportGroupsUrl}
                                        title="View Support Groups"
                                        className="w-48"
                                    />
                                ) : null}
                            </div>
                        </div>
                    ) : null}

                    <hr className="mt-4" />

                    <div className="mt-4">
                        <div className="flex justify-between items-start space-x-4">
                            <div>
                                <div
                                    className={readMore ? "" : "max-h-72 overflow-y-hidden"}
                                >
                                    {/*<ReactMarkdown className="prose max-w-screen">
                                        {community.description}
                                    </ReactMarkdown>*/}

                                    <p className="prose max-w-screen whitespace-pre-wrap">
                                        {community.description}
                                    </p>
                                </div>

                                <button
                                    onClick={() => setReadMore(!readMore)}
                                    className="mt-4 px-2 py-1 flex items-center text-primary-400 rounded hover:bg-gray-100"
                                >
                                    {readMore ? "Show Less" : "Show More"}
                                    {readMore ? (
                                        <ChevronUpIcon className="ml-2 w-4 h-4" />
                                    ) : (
                                        <ChevronDownIcon className="ml-2 w-4 h-4" />
                                    )}
                                </button>
                            </div>

                            <div className="flex flex-col">
                                {paidPlan && community.brochureUrl ? (
                                    <div className="mb-4">
                                        <ViewDocument
                                            href={community.brochureUrl}
                                            title="View Brochure"
                                        />
                                    </div>
                                ) : null}

                                {paidPlan ? (
                                    <Button
                                        variant="contained"
                                        size="xs"
                                        className="w-28"
                                        onClick={() => setOpenGallery(2)}
                                    >
                                        <div className="text-center uppercase font-bold">
                                            View Files
                                        </div>
                                    </Button>
                                ) : null}
                            </div>

                            {enableEdit ? <EditButton step={7} onEdit={onEdit} /> : null}
                        </div>
                    </div>
                </div>

                {paidPlan ? (
                    <div className="mt-8 sm:mt-0 space-y-4">
                        {logoImage ? (
                            <div className="relative w-full h-[75px]">
                                <PdImage
                                    src={removeExt(logoImage.url) + ".sm.jpg"}
                                    alt="Community Logo"
                                    className="object-contain object-center w-full h-full"
                                />
                                {/*<Image
                                loader={imageLoader}
                                src={removeExt(logoImage.url) + ".sm.jpg"}
                                alt="Community Logo"
                                layout="fill"
                                objectFit="contain"
                                objectPosition="center"
                                priority
                                unoptimized={false}
                            />*/}
                            </div>
                        ) : null}
                        <CommunitySocialButtons community={community} />
                        <BookYourTourCard community={community} />
                        <AskQuestionCard
                            community={community}
                            step={2}
                            onEdit={onEdit}
                            enableEdit={enableEdit}
                        />
                    </div>
                ) : (
                    <div className="mt-8 sm:mt-0 space-y-4">
                        <RequestInfoCard community={community} phone={phone} />
                    </div>
                )}
            </div>

            <div>
                <div id="id-section-units/pricing">
                    <CommunityTemplates
                        community={community}
                        onEditTemplate={onEditTemplate}
                        onEditTemplateImages={onEditTemplateImages}
                        onAddTemplate={onAddTemplate}
                        onEditUnit={onEditUnit}
                        onAddUnit={onAddUnit}
                        enableEdit={enableEdit}
                    />
                </div>

                <div className="relative mt-8 pb-16 sm:grid sm:grid-cols-3 sm:gap-x-8">
                    <div className="sm:col-span-2">
                        <SectionGroup
                            id="id-section-deposits/fees"
                            title="Community Deposits and Fees* (Deposits and fees may differ on individual units)"
                            cols="grid-cols-2 sm:grid-cols-3"
                            border="border-t border-r border-l rounded-t"
                            enableEdit={enableEdit}
                            onEdit={onEdit}
                            step={4}
                        >
                            <Section
                                title="Community Deposits and Fees"
                                className="sm:col-span-2"
                            >
                                <UnorderedList>
                                    <li>{community.deposit}</li>
                                    <li>{community.communityFee}</li>
                                    <li>{community.careFee}</li>
                                    <li>{community.secondPersonFee}</li>
                                    <li>{community.parkingFee}</li>
                                </UnorderedList>
                            </Section>

                            <Section title="Pet Deposit and Fee">
                                <UnorderedList>
                                    <li>{community.petDeposit}</li>
                                    <li>{community.monthlyPetFee}</li>
                                </UnorderedList>
                            </Section>

                            {community.depositsAndFeesInfo ? (
                                <Section
                                    title="Additional Information for Community Deposits And Fees"
                                    className="sm:col-span-3"
                                >
                                    <div>
                                        <p className="px-4 prose prose-sm whitespace-pre-wrap">
                                            {community.depositsAndFeesInfo}
                                        </p>
                                    </div>
                                </Section>
                            ) : null}
                        </SectionGroup>

                        <SectionGroup
                            title="Community Details"
                            cols="grid-cols-2 sm:grid-cols-3"
                            enableEdit={enableEdit}
                            onEdit={onEdit}
                            step={4}
                        >
                            <Section title="Community">
                                <UnorderedList>
                                    {community.yearBuilt ? (
                                        <li>
                                            Built in {community.yearBuilt.split("-")[0]}
                                        </li>
                                    ) : null}

                                    {community.numberOfUnits ? (
                                        <li>
                                            {community.numberOfUnits > 100
                                                ? "100+"
                                                : community.numberOfUnits}{" "}
                                            Units
                                        </li>
                                    ) : null}

                                    {community.numberOfFloors ? (
                                        <li>{community.numberOfFloors} Floors</li>
                                    ) : null}

                                    {community.numberOfElevators ? (
                                        <li>{community.numberOfElevators} Elevators</li>
                                    ) : null}

                                    {/*community.privatePay === true ? (
                                        <li>Private Pay Available (?)</li>
                                    ) : null*/}

                                    {community.washerDryer === true ? (
                                        <li>Washer Dryer Available (?)</li>
                                    ) : null}
                                </UnorderedList>
                            </Section>

                            <Section title="Parking">
                                <UnorderedList>
                                    {community.parking
                                        ? community.parking
                                              .split(";")
                                              .map((p) => <li key={p}>{p}</li>)
                                        : null}
                                </UnorderedList>
                            </Section>

                            <Section title="Property" className="col-span-2 sm:col-span-1">
                                <UnorderedList>
                                    {community.propertySmoking ? (
                                        <li>
                                            Property Smoking - {community.propertySmoking}
                                        </li>
                                    ) : null}

                                    {community.propertyManagerOnsite ? (
                                        <li>
                                            Property Manager Onsite -{" "}
                                            {community.propertyManagerOnsite === "Yes"
                                                ? "Yes"
                                                : "No"}
                                        </li>
                                    ) : null}

                                    {community.propertyMaintenanceOnsite ? (
                                        <li>
                                            Property Maintenance Onsite -{" "}
                                            {community.propertyMaintenanceOnsite === "Yes"
                                                ? "Yes"
                                                : "No"}
                                        </li>
                                    ) : null}
                                </UnorderedList>
                            </Section>

                            {community.visitingHours ? (
                                <Section
                                    title="Visiting Hours And Rules"
                                    className="sm:col-span-2"
                                >
                                    <UnorderedList>
                                        <li>{community.visitingHours}</li>
                                    </UnorderedList>
                                </Section>
                            ) : null}

                            {community.covidInfo ? (
                                <Section
                                    title="Health and Covid-Related Information"
                                    className="sm:col-span-2"
                                >
                                    <UnorderedList>
                                        <li>{community.covidInfo}</li>
                                    </UnorderedList>
                                </Section>
                            ) : null}

                            {community.security ? (
                                <Section title="Security">
                                    <UnorderedList>
                                        {community.security.split(";").map((p) => (
                                            <li key={p}>{p}</li>
                                        ))}
                                    </UnorderedList>
                                </Section>
                            ) : null}
                        </SectionGroup>

                        <SectionGroup
                            id="id-section-care-info"
                            title="Care Information"
                            cols="grid-cols-1 sm:grid-cols-3"
                            enableEdit={enableEdit}
                            onEdit={onEdit}
                            step={5}
                        >
                            {careGiver.length > 0 ? (
                                <Section
                                    title="Care Giver"
                                    className="col-span-2 sm:col-span-1"
                                >
                                    <UnorderedList>
                                        {careGiver.map((option) => (
                                            <li key={option}>{option}</li>
                                        ))}
                                    </UnorderedList>
                                </Section>
                            ) : null}

                            {community.languages ? (
                                <Section
                                    title="Languages"
                                    className="col-span-2 sm:col-span-1"
                                >
                                    <UnorderedList>
                                        <li>{community.languages}</li>
                                    </UnorderedList>
                                </Section>
                            ) : null}

                            <Section
                                title="Care Giver Hours"
                                className="col-span-2 sm:col-span-1"
                            >
                                <UnorderedList>
                                    <li>Care Giver Hours - {community.careNurseHours}</li>
                                </UnorderedList>
                            </Section>

                            {paidPlan && community.careInfoUrl ? (
                                <div className="flex justify-end">
                                    <div className="mt-2 mr-8 space-y-2">
                                        <ViewDocument
                                            href={community.careInfoUrl}
                                            title="View Care Information"
                                        />
                                        <Button
                                            variant="contained"
                                            size="xs"
                                            className="w-28"
                                            onClick={() => setOpenGallery(2)}
                                        >
                                            <div className="text-center uppercase font-bold">
                                                View Files
                                            </div>
                                        </Button>
                                    </div>
                                </div>
                            ) : null}

                            {community.careLift ? (
                                <Section
                                    title="Care Giver Lift"
                                    className="col-span-2 sm:col-span-1"
                                >
                                    <UnorderedList>
                                        {community.careLift.split(";").map((option) => (
                                            <li key={option}>{option}</li>
                                        ))}
                                    </UnorderedList>
                                </Section>
                            ) : null}

                            <Section
                                title="Additional Care Services"
                                className="sm:col-span-2"
                            >
                                <UnorderedList cols="grid-cols-2">
                                    {care.map((option) => (
                                        <li key={option}>{option}</li>
                                    ))}
                                </UnorderedList>
                            </Section>

                            {community.careInjections ? (
                                <Section
                                    title="Injections"
                                    className="col-span-2 sm:col-span-1"
                                >
                                    <UnorderedList>
                                        {community.careInjections
                                            .split(";")
                                            .map((option) => (
                                                <li key={option}>{option}</li>
                                            ))}
                                    </UnorderedList>
                                </Section>
                            ) : null}

                            {community.careInfo ? (
                                <Section
                                    title="Additional Care Information"
                                    className="sm:col-span-3"
                                >
                                    <div>
                                        <p className="px-4 prose prose-sm whitespace-pre-wrap">
                                            {community.careInfo}
                                        </p>
                                    </div>
                                </Section>
                            ) : null}
                        </SectionGroup>

                        <SectionGroup
                            id="id-section-food/dining"
                            title="Food and Dining"
                            cols="grid-cols-2 sm:grid-cols-3"
                            enableEdit={enableEdit}
                            onEdit={onEdit}
                            step={6}
                        >
                            <div className="col-span-2 sm:col-span-3">
                                {/*<ReactMarkdown className="py-4 px-4 prose prose-sm">
                                    {community.diningInfo}
                                </ReactMarkdown>*/}

                                <div className="flex flex-col sm:flex-row justify-between">
                                    <p className="py-4 px-4 prose prose-sm whitespace-pre-wrap">
                                        {community.diningInfo}
                                    </p>

                                    <div className="mb-4 sm:mt-4 mr-8 flex flex-col items-end">
                                        {paidPlan && community.menuUrl ? (
                                            <div className="mb-4 space-y-2">
                                                <ViewDocument
                                                    href={community.menuUrl}
                                                    title="View Menu"
                                                />
                                                <Button
                                                    variant="contained"
                                                    size="xs"
                                                    className="w-28"
                                                    onClick={() => setOpenGallery(2)}
                                                >
                                                    <div className="text-center uppercase font-bold">
                                                        View Files
                                                    </div>
                                                </Button>
                                            </div>
                                        ) : null}

                                        <div className="flex flex-col items-end space-y-2">
                                            {diningImages
                                                .filter((_, i) => i < 3)
                                                .map((m) => (
                                                    <div
                                                        key={m.url}
                                                        className="relative w-28 h-16"
                                                    >
                                                        <PdImage
                                                            src={
                                                                removeExt(m.url) + ".th.jpg"
                                                            }
                                                            alt={m.title}
                                                        />
                                                        {/*<Image
                                                            loader={imageLoader}
                                                            src={
                                                                removeExt(m.url) + ".sm.jpg"
                                                            }
                                                            alt={m.title}
                                                            layout="fill"
                                                            objectFit="cover"
                                                            objectPosition="center"
                                                            priority
                                                            unoptimized={true}
                                                        />*/}
                                                    </div>
                                                ))}

                                            {diningImages.length > 0 ? (
                                                <Button
                                                    variant="contained"
                                                    size="xs"
                                                    className="w-28 uppercase font-bold text-center"
                                                    onClick={() => setOpenGallery(0)}
                                                >
                                                    View Photos
                                                </Button>
                                            ) : null}
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <Section title="Food Preparation">
                                <UnorderedList>
                                    {community.foodPreparation
                                        ? community.foodPreparation
                                              .split(";")
                                              .map((p) => <li key={p}>{p}</li>)
                                        : null}
                                </UnorderedList>
                            </Section>

                            <Section title="Food options" className="sm:col-span-2">
                                <UnorderedList cols="grid-cols-2 sm:grid-cols-2">
                                    {community.foodOptions
                                        ? community.foodOptions
                                              .split(";")
                                              .map((p) => <li key={p}>{p}</li>)
                                        : null}
                                </UnorderedList>
                            </Section>

                            <Section title="Dining" className="sm:col-span-2">
                                <UnorderedList cols="sm:grid-cols-2">
                                    {dining.map((p) => (
                                        <li key={p}>{p}</li>
                                    ))}
                                </UnorderedList>
                            </Section>
                        </SectionGroup>

                        <SectionGroup
                            title="Amenities"
                            cols="grid-cols-1"
                            enableEdit={enableEdit}
                            onEdit={onEdit}
                            step={8}
                        >
                            {/*<ReactMarkdown className="py-4 px-4 prose prose-sm">
                                {community.amenitiesInfo}
                            </ReactMarkdown>*/}

                            <div className="flex flex-col sm:flex-row justify-between">
                                <p className="py-4 px-4 prose prose-sm whitespace-pre-wrap">
                                    {community.amenitiesInfo}
                                </p>

                                <div className="mb-4 sm:mt-4 mr-8 flex flex-col items-end space-y-2">
                                    {amenitiesImages
                                        .filter((_, i) => i < 3)
                                        .map((m) => (
                                            <div key={m.url} className="relative w-28 h-16">
                                                <PdImage
                                                    src={removeExt(m.url) + ".th.jpg"}
                                                    alt={m.title}
                                                />
                                                {/*<Image
                                                    loader={imageLoader}
                                                    src={removeExt(m.url) + ".sm.jpg"}
                                                    alt={m.title}
                                                    layout="fill"
                                                    objectFit="cover"
                                                    objectPosition="center"
                                                    priority
                                                    unoptimized={true}
                                                />*/}
                                            </div>
                                        ))}

                                    <Button
                                        variant="contained"
                                        size="xs"
                                        className="w-28 uppercase font-bold text-center"
                                        onClick={() => setOpenGallery(0)}
                                    >
                                        View Photos
                                    </Button>
                                </div>
                            </div>

                            <Section title="Amenities">
                                <UnorderedList cols="grid-cols-2 sm:grid-cols-3">
                                    {amenities.map((p) => (
                                        <li key={p}>{p}</li>
                                    ))}
                                </UnorderedList>
                            </Section>
                        </SectionGroup>

                        <SectionGroup
                            title="Activities"
                            cols="grid-cols-1"
                            border="border"
                            enableEdit={enableEdit}
                            onEdit={onEdit}
                            step={9}
                        >
                            <Section title="Activities">
                                {/*<ReactMarkdown className="py-4 px-4 prose prose-sm">
                                    {community.activitiesInfo}
                                </ReactMarkdown>*/}

                                <div className="flex flex-col sm:flex-row justify-between">
                                    <div>
                                        <p className="py-4 px-4 prose prose-sm whitespace-pre-wrap">
                                            {community.activitiesInfo}
                                        </p>
                                    </div>

                                    <div className="mb-4 sm:mt-4 mr-8 flex flex-col items-end">
                                        {paidPlan && community.calendarUrl ? (
                                            <div className="mb-4 space-y-2">
                                                <ViewDocument
                                                    href={community.calendarUrl}
                                                    title="View Calendar"
                                                />

                                                <Button
                                                    variant="contained"
                                                    size="xs"
                                                    className="w-28"
                                                    onClick={() => setOpenGallery(2)}
                                                >
                                                    <div className="text-center uppercase font-bold">
                                                        View Files
                                                    </div>
                                                </Button>
                                            </div>
                                        ) : null}

                                        <div className="flex flex-col items-end space-y-2">
                                            {activitiesImages
                                                .filter((_, i) => i < 3)
                                                .map((m) => (
                                                    <div
                                                        key={m.url}
                                                        className="relative w-28 h-16"
                                                    >
                                                        <PdImage
                                                            src={
                                                                removeExt(m.url) + ".th.jpg"
                                                            }
                                                            alt={m.title}
                                                        />
                                                        {/*<Image
                                                            loader={imageLoader}
                                                            src={
                                                                removeExt(m.url) + ".sm.jpg"
                                                            }
                                                            alt={m.title}
                                                            layout="fill"
                                                            objectFit="cover"
                                                            objectPosition="center"
                                                            priority
                                                            unoptimized={true}
                                                        />*/}
                                                    </div>
                                                ))}

                                            <Button
                                                variant="contained"
                                                size="xs"
                                                className="w-28 uppercase font-bold text-center"
                                                onClick={() => setOpenGallery(0)}
                                            >
                                                View Photos
                                            </Button>
                                        </div>
                                    </div>
                                </div>

                                <UnorderedList cols="grid-cols-2 sm:grid-cols-3">
                                    {activities.map((p) => (
                                        <li key={p} className="text-gray-700">
                                            {p}
                                        </li>
                                    ))}
                                </UnorderedList>
                            </Section>
                        </SectionGroup>
                    </div>

                    {paidPlan && floorPlansConfigured ? (
                        <div className="hidden sm:block sm:sticky top-[144px] h-96 space-y-4">
                            <BookYourTourCard community={community} />
                            <AskQuestionCard
                                community={community}
                                step={2}
                                onEdit={onEdit}
                                enableEdit={enableEdit}
                            />
                        </div>
                    ) : null}
                </div>
            </div>

            {openGallery >= 0 ? (
                <CommunityDialogGalleryOnboarded
                    open={true}
                    onClose={() => setOpenGallery(-1)}
                    community={community}
                    initialTab={openGallery}
                />
            ) : null}

            {!preview && !sidebar ? <SeoLinks /> : null}
            {!preview && !sidebar ? <HomeFooter /> : null}

            <UserVisitCommunity
                community={community}
                enable={!preview}
                disableEmail={community.disableUserVisitEmail}
            />
        </div>
    );
};

const EditButton = ({ step, onEdit, className }) => {
    return (
        <Tooltip title="Edit">
            <IconButton
                onClick={() => onEdit(step)}
                className={className}
                data-tippy-content="Tooltip placement top"
                data-tippy-placement="top"
            >
                <PencilIcon className="w-6 h-6 text-primary-500" />
            </IconButton>
        </Tooltip>
    );
};

export const SectionGroup = ({
    id,
    title,
    children,
    border = "border-t border-r border-l",
    cols = "grid-cols-3",
    enableEdit,
    onEdit,
    step,
}) => {
    return (
        <div
            id={id ? id : "id-section-" + title.toLowerCase().replaceAll(" ", "-")}
            className={border + " border-gray-300"}
        >
            <div className="flex justify-between bg-gray-100 border-b border-gray-300">
                <h2 className="px-4 py-2 flex-grow font-semibold">{title}</h2>
                {enableEdit ? <EditButton step={step} onEdit={onEdit} /> : null}
            </div>
            <div className={"sm:grid " + cols}>{children}</div>
        </div>
    );
};

export const Section = ({ title, children, className = "" }) => {
    return (
        <div className={"py-2 " + className}>
            <h3 className="px-4 font-semibold text-gray-900">{title}</h3>
            {children}
        </div>
    );
};

export const UnorderedList = ({ children, cols = "grid-cols-1" }) => {
    return (
        <ul className={"mt-1 px-4 gap-x-6 grid " + cols + " text-sm text-gray-600"}>
            {children}
        </ul>
    );

    /*return (
        <ul className="px-6 list-disc grid grid-cols-1 text-sm text-gray-600">
            {children}
        </ul>
    );*/
};

const ViewDocument = ({ href, title, openInNewTab = true, className = "w-28" }) => {
    const handleClick = () => {
        window.open(href, "_blank").focus();
    };

    if (openInNewTab) {
        return (
            <div className={className}>
                <button
                    onClick={handleClick}
                    className="block w-full text-center px-2 py-1 text-primary-100 bg-primary-500 uppercase text-xs font-bold rounded hover:bg-primary-400"
                >
                    {title}
                </button>
            </div>
        );
    }

    return (
        <div className={className}>
            <Link href={href} rel="nofollow">
                <a
                    rel="nofollow"
                    className="block px-2 py-1 text-primary-100 bg-primary-500 uppercase text-xs font-bold rounded hover:bg-primary-400"
                >
                    {title}
                </a>
            </Link>
        </div>
    );
};

const Item = ({ label, children }) => {
    return (
        <div className="flex">
            <h3 className="w-64 text-gray-700">{label}</h3>
            <div className="w-48 text-gray-900 text-right">{children}</div>
        </div>
    );
};

const ScrollButton = ({ title }) => {
    const { zoom } = useContext(LayoutContext);

    return (
        <button
            className="px-2 py-0.5 hover:underline font-bold"
            onClick={() => {
                const e = document.getElementById(
                    "id-section-" + title.toLowerCase().replaceAll(" ", "-")
                );

                if (e) {
                    const yOffset = -136;

                    const y = e.getBoundingClientRect().top + window.pageYOffset + yOffset;

                    console.log(
                        "scroll",
                        e.getBoundingClientRect().top,
                        window.pageYOffset,
                        yOffset,
                        y
                    );

                    window.scrollTo({ top: y, behavior: "smooth" });
                }
            }}
        >
            {title}
        </button>
    );
};

export default CommunityMainInfoOnboarded;
