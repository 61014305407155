import React from "react";

import {
    XIcon,
    ChevronLeftIcon,
    ChevronRightIcon,
    LockClosedIcon,
    LockOpenIcon,
} from "@heroicons/react/outline";

import Button from "@/ui/Button";
import { IconButton } from "@/ui/IconButton";

export const Title = ({ children, onClose, preview = false, disabled = false }) => {
    return (
        <div className="flex justify-between items-center">
            <h2 className="text-2xl font-headline font-bold text-primary-500">
                {children}
            </h2>
            {preview ? (
                <IconButton onClick={onClose} disabled={disabled}>
                    <XIcon className="w-6 h-6" />
                </IconButton>
            ) : null}
        </div>
    );
};

export const PaidLabel = ({ children, community, size = "md", enableButton = true }) => {
    const paidPlan = community.plan && community.plan.startsWith("Paid");

    return (
        <div className="flex items-center">
            <span>{children}</span>

            {enableButton && !paidPlan ? (
                <span
                    className={`ml-2 ${
                        size === "sm" ? "px-3 py-0.5" : "px-3 py-1"
                    } bg-primary-500 text-primary-50 rounded-full text-sm`}
                >
                    Premium Only
                </span>
            ) : null}

            <span className="ml-1">
                {!paidPlan ? <LockClosedIcon className="w-5 h-5 text-primary-500" /> : null}
            </span>
        </div>
    );
};

export const PlanLabel = ({ community, size = "md" }) => {
    if (community.plan === "Paid") {
        return (
            <div className="flex">
                <div className="px-6 py-1 text-sm font-bold text-primary-900 bg-primary-100 rounded-full border border-primary-300">
                    Premium Plan
                </div>
                {/*<button
                    onClick={handleDowngrade}
                    className="ml-2 px-6 py-1 text-sm text-primary-500 bg-primary-100 rounded-full border border-primary-300 hover:bg-primary-50"
                >
                    Downgrade Plan
                </button>*/}
            </div>
        );
    } else {
        return (
            <div className="flex">
                <div className="px-6 py-1 text-sm font-bold text-primary-900 bg-primary-100 rounded-full border border-primary-300">
                    Free plan
                </div>
                {/*<button
                    onClick={handleUpgrade}
                    className="ml-2 px-6 py-1 text-sm text-primary-500 bg-primary-100 rounded-full border border-primary-300 hover:bg-primary-50"
                >
                    Upgrade Plan
                </button>*/}
            </div>
        );
    }
};

export const SaveAndBack = ({
    onBack,
    preview,
    onSave,
    saveTitle = "Save",
    disabled = false,
}) => {
    return (
        <div
            className={`mt-4 flex ${onBack ? "justify-between" : "justify-end"} col-span-2`}
        >
            {onBack ? (
                <Button
                    variant="outlined"
                    onClick={onBack}
                    className="flex items-center"
                    size="md"
                    disabled={disabled}
                >
                    <ChevronLeftIcon className="w-5 h-5 -ml-2 mr-2" />
                    Back
                </Button>
            ) : null}

            {onSave ? (
                <Button
                    variant="contained"
                    size="md"
                    type="button"
                    className="flex items-center"
                    onClick={onSave}
                    disabled={disabled}
                >
                    {saveTitle}
                    {preview ? null : <ChevronRightIcon className="w-5 h-5 ml-2" />}
                </Button>
            ) : (
                <Button
                    variant="contained"
                    size="md"
                    type="submit"
                    className="flex items-center"
                    disabled={disabled}
                >
                    {saveTitle}
                    {preview ? null : <ChevronRightIcon className="w-5 h-5 ml-2" />}
                </Button>
            )}
        </div>
    );
};
