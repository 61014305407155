import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { saveAs } from "file-saver";

import { HeartIcon, PrinterIcon, DocumentDownloadIcon } from "@heroicons/react/outline";
import { HeartIcon as SolidHeartIcon } from "@heroicons/react/solid";

import { toggleFavourite, toggleExOut } from "../../actions/localActions";
import Tooltip from "@/ui/Tooltip";
import ShareButton from "../common/ShareButton";
import { toBlob } from "./CommunityMls";
import { trackButtonClick } from "../user/UserUtils";

const CommunityActions = ({ community, dark = false, phone }) => {
    const dispatch = useDispatch();
    const { favourites, exOut } = useSelector((state) => state.local);

    const handleFavourite = (e) => {
        e.stopPropagation();
        dispatch(toggleFavourite(community.nameId));
    };

    const handlePrint = (e) => {
        e.stopPropagation();

        if (phone) {
            window.open(
                "/community-mls/" +
                    community.nameId +
                    "?phone=" +
                    encodeURIComponent(phone),
                "_blank"
            );
        } else {
            window.open("/community-mls/" + community.nameId, "_blank");
        }
    };

    const handleDownload = async (e) => {
        e.stopPropagation();
        const blob = await toBlob(community, phone);

        saveAs(blob, community.nameId + ".pdf");
    };

    return (
        <div className={`flex items-center`}>
            <Tooltip title="Download" placement="right">
                <button onClick={handleDownload}>
                    <DocumentDownloadIcon
                        className={`w-8 h-8 p-1 hover:rounded-full
                         ${
                             dark
                                 ? "text-gray-50 hover:text-primary-500 hover:bg-gray-200"
                                 : "text-primary-500 hover:text-white hover:bg-primary-400"
                         }`}
                    />
                </button>
            </Tooltip>
            <Tooltip title="Print" placement="right">
                <button onClick={handlePrint}>
                    <PrinterIcon
                        className={`w-8 h-8 p-1 hover:rounded-full
                         ${
                             dark
                                 ? "text-gray-50 hover:text-primary-500 hover:bg-gray-200"
                                 : "text-primary-500 hover:text-white hover:bg-primary-400"
                         }`}
                    />
                </button>
            </Tooltip>
            <Tooltip title="Favorite" placement="right">
                <button onClick={handleFavourite}>
                    {favourites && favourites[community.nameId] ? (
                        <SolidHeartIcon
                            className={`w-8 h-8 p-1 hover:rounded-full
                        ${
                            dark
                                ? "text-gray-50 hover:text-primary-500 hover:bg-gray-200"
                                : "text-primary-500 hover:text-white hover:bg-primary-400"
                        }`}
                        />
                    ) : (
                        <HeartIcon
                            className={`w-8 h-8 p-1 hover:rounded-full
                        ${
                            dark
                                ? "text-gray-50 hover:text-primary-500 hover:bg-gray-200"
                                : "text-primary-500 hover:text-white hover:bg-primary-400"
                        }`}
                        />
                    )}
                </button>
            </Tooltip>
            <Tooltip title="Share" placement="right">
                <ShareButton community={community} dark={dark} />
            </Tooltip>
        </div>
    );
};

export const FavoriteButton = ({ community }) => {
    const dispatch = useDispatch();
    const { favourites } = useSelector((state) => state.local);

    const handleFavourite = (e, favoriteState, _favourites) => {
        e.stopPropagation();
        dispatch(toggleFavourite(community.nameId));

        gtag("event", "favorite", {
            event_category: "engagement",
            event_label: "favorite_button",
            value: favoriteState ? 0 : 1,
            item_id: community.nameId,
        });

        gtag("event", "engagementCountUpdated", {
            favorites_count: _favourites ? Object.keys(_favourites).length : 1,
        });

        const engagementCount = trackButtonClick("engagement");
        gtag("event", "engagementCountUpdated", {
            engagement_count: engagementCount,
        });

        gtag("event", "conversion", {
            send_to: "AW-10778046073/_TsFCL_3vpMaEPncr5Mo",
        });
    };

    return (
        <div className="flex flex-col items-center">
            <button
                className="px-2 py-2 bg-gray-100 hover:bg-gray-200 rounded-full"
                onClick={(e) =>
                    handleFavourite(
                        e,
                        favourites && favourites[community.nameId],
                        favourites
                    )
                }
            >
                {favourites && favourites[community.nameId] ? (
                    <SolidHeartIcon className="w-16 h-16 p-1 text-primary-500" />
                ) : (
                    <HeartIcon className="w-16 h-16 p-1 text-primary-500 animate-wiggle-favorite" />
                )}
            </button>
            {!favourites || !favourites[community.nameId] ? (
                <div className="text-xs text-center">Add to Favorites</div>
            ) : null}
        </div>
    );
};

export default CommunityActions;
