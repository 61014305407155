import React from "react";

import TextField from "./TextField";

const TextFieldFormik = ({
    id,
    name,
    type,
    select,
    label,
    formik,
    required,
    disabled,
    helperText,
    fullwidth,
    className,
    children,
    options,
    maxLength,
    InputComponent,
    inputClassName,
}) => {
    return (
        <TextField
            id={id}
            name={name}
            type={type}
            select={select}
            label={label}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name] ? formik.errors[name] : helperText}
            dirty={formik.initialValues[name] !== formik.values[name]}
            touched={formik.touched[name]}
            required={required}
            disabled={disabled}
            fullwidth={fullwidth}
            className={className}
            options={options}
            maxLength={maxLength}
            InputComponent={InputComponent}
            inputClassName={inputClassName}
        >
            {children}
        </TextField>
    );
};

/**
 *             error={formik.touched[name] && Boolean(formik.errors[name])}
            helperText={formik.touched[name] && formik.errors[name]}
 */

export default TextFieldFormik;
