import React, { useState, useEffect } from "react";

import { XIcon } from "@heroicons/react/outline";

import { useFormik } from "formik";
import * as yup from "yup";

import Dialog from "@/ui/Dialog";
import TextFieldFormik from "@/ui/TextFieldFormik";
import { TextAreaFormik } from "@/ui/TextArea";
import Button from "@/ui/Button";
import { CheckboxFormik } from "@/ui/Checkbox";
import { readDocument, updateDocument } from "@/lib/db";
import { useAuth } from "@/lib/auth";
import SignUpSignIn from "../user/SignUpSignIn";
import { LightButton, DarkButton } from "../community/CTACards";
import { IconButton } from "@/ui/IconButton";
import { YouNeedToRegister, getContactFromCommunity, getReceiveEmails } from "./utils";
import { trackButtonClick } from "../user/UserUtils";

const updateLocalStorage = (user) => {
    const _name = localStorage.getItem("name");
    const _email = localStorage.getItem("email");
    const _phone = localStorage.getItem("phone");

    if (!_name && user.name) {
        localStorage.setItem("name", user.name);
    }

    if (!_email && user.email) {
        localStorage.setItem("email", user.email);
    }

    if (!_phone && user.phone) {
        localStorage.setItem("phone", user.phone);
    }
};

const readLocalStorage = () => {
    const name = localStorage.getItem("name");
    const email = localStorage.getItem("email");
    const phone = localStorage.getItem("phone");

    return { name, email, phone };
};

const AskQuestion = ({ community }) => {
    const auth = useAuth();
    const [open, setOpen] = useState(false);
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openSignIn, setOpenSignIn] = useState(false);
    const [user, setUser] = useState({});
    const [complete, setComplete] = useState(false);
    const [email, setEmail] = useState("");
    const [initialValues, setInitialValues] = useState({});

    const userId = auth && auth.user ? auth.user.uid : "";
    const token = auth && auth.user ? auth.user.token : "";

    const contact = getContactFromCommunity(community);
    const communityEmails = getReceiveEmails(community);

    useEffect(() => {
        const getUser = async (uid) => {
            const _user = await readDocument("users", uid);
            updateLocalStorage(_user);
            setUser(_user);
        };

        if (userId) {
            getUser(userId);
        }
    }, [userId]);

    useEffect(() => {
        const values = readLocalStorage();
        setInitialValues(values);
    }, []);

    const handleClose = () => {
        setOpen(false);
        setComplete(false);
    };

    const handleComplete = async ({ createAccount, values }) => {
        const res = await fetch("/api/email/ask-a-question-claimed", {
            method: "post",
            headers: {
                "Content-Type": "application/json",
                token,
            },
            body: JSON.stringify(
                {
                    ...data,
                    title: `Ask a Question about ${community.name}`,
                    preview: `Ask a Question about ${community.name}`,
                    contact,
                    communityEmails,
                },
                null,
                2
            ),
        });

        updateLocalStorage(values);

        if (createAccount) {
            const { name, email } = values;

            const password = ""; // will be generated randomly
            const receivedEmailUpdates = true;
            const emailLink = true;

            try {
                await auth.signUp(email, password, name, receivedEmailUpdates, emailLink);

                gtag("event", "conversion", {
                    send_to: "AW-10778046073/z0kvCMDQspMaEPncr5Mo",
                });
            } catch (err) {
                console.error("Error creating account", err);

                if (emailLink && err.code === "auth/email-already-in-use") {
                    // sign in the user with email link
                    await auth.signInWithUserEmailLink(email);
                }
            }
        }

        setComplete(true);
    };

    return (
        <div>
            <DarkButton
                className="animate-wiggle-cta animation-delay-3000"
                onClick={() => {
                    const value = trackButtonClick("ask_question_button");
                    gtag("event", "ask_question", {
                        event_category: "engagement",
                        event_label: "ask_question_button",
                        value,
                        item_id: community.nameId,
                    });

                    const engagementCount = trackButtonClick("engagement");
                    gtag("event", "engagementCountUpdated", {
                        engagement_count: engagementCount,
                    });

                    gtag("event", "conversion", {
                        send_to: "AW-10778046073/h8qICIDHs5MaEPncr5Mo",
                    });

                    setOpen(true);
                }}
            >
                Ask a Question
            </DarkButton>

            <Dialog
                open={open}
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={handleClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed -z-10 inset-0 bg-black opacity-70" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <div
                        className={`inline-block w-full max-w-lg pb-8 overflow-hidden
                                            text-left align-middle transition-all transform 
                                            bg-white shadow-xl rounded-2xl`}
                    >
                        <div className="flex justify-end"></div>

                        <Dialog.Title className="px-4 py-2 flex justify-between items-center bg-primary-500">
                            <div className="flex justify-center flex-grow">
                                <h3 className="text-2xl font-bold font-headline uppercase text-white">
                                    Ask A Question
                                </h3>
                            </div>
                            <IconButton onClick={handleClose}>
                                <XIcon className="w-6 h-6 text-white" />
                            </IconButton>
                        </Dialog.Title>

                        {/*userId === "" ? (
                            <YouNeedToRegister
                                setOpenSignIn={setOpenSignIn}
                                setOpenSignUp={setOpenSignUp}
                                onClose={handleClose}
                            />
                        ) : null*/}

                        {
                            /*userId !== "" &&*/ !complete ? (
                                <AskQuestionForm
                                    community={community}
                                    user={user}
                                    initialValues={initialValues}
                                    onComplete={handleComplete}
                                />
                            ) : null
                        }

                        {complete ? (
                            <AskQuestionComplete community={community} email={email} />
                        ) : null}

                        {!user?.uid ? (
                            <div className="px-8 mt-2 text-sm flex space-x-2">
                                <div className="flex items-center space-x-2">
                                    <div>Already have an account?</div>
                                    <buton
                                        className="font-bold text-primary-500 underline hover:text-primary-400 hover:cursor-pointer"
                                        onClick={() => {
                                            handleClose();
                                            setOpenSignIn(true);
                                        }}
                                    >
                                        Sign In
                                    </buton>
                                </div>

                                <div className="flex items-center space-x-2">
                                    <div>or</div>

                                    <buton
                                        className="font-bold text-primary-500 underline hover:text-primary-400 hover:cursor-pointer"
                                        onClick={() => {
                                            handleClose();
                                            setOpenSignUp(true);
                                        }}
                                    >
                                        Sign Up
                                    </buton>

                                    <div>to create an account.</div>
                                </div>
                            </div>
                        ) : null}
                    </div>
                </div>
            </Dialog>

            <SignUpSignIn
                openSignUp={openSignUp}
                openSignIn={openSignIn}
                setOpenSignUp={setOpenSignUp}
                setOpenSignIn={setOpenSignIn}
            />
        </div>
    );
};

const validationSchema = yup.object({
    name: yup.string().required("Name is required").nullable(),
    email: yup.string().required("Email is required").nullable(),
    phone: yup.string().required("Mobile Number is required").nullable(),
    message: yup.string().required("Message is required").nullable(),
});

const AskQuestionForm = ({ community, user, initialValues, onComplete }) => {
    // const [name, setName] = useState(user.name);
    // const [phone, setPhone] = useState(user.phone);
    // const [topic, setTopic] = useState("");
    // const [message, setMessage] = useState("");
    // const [urgent, setUrgent] = useState(false);

    const topics = [
        "Vacancy",
        "Cost + Affordability",
        "Qualifications",
        "Property + Services",
        "Tour Property",
        "Other",
    ].map((x) => ({ value: x, name: x }));

    const formik = useFormik({
        initialValues: {
            name: user.name ? user.name : initialValues.name,
            email: user.email ? user.email : initialValues.email,
            phone: user.phone ? user.phone : initialValues.phone,
            topic: "",
            message: "",
            urgent: false,
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            // TODO - use the token when sending an email??????????????????
            //const token = auth && auth.user ? auth.user.token : "";

            // TODO - send emails...

            console.log("values", values);

            if (values.name !== user.name || values.phone !== user.phone) {
                await updateDocument("users", user.uid, {
                    ...user,
                    name: values.name ? values.name : user.anme,
                    phone: values.phone ? values.phone : user.phone,
                });
            }

            onComplete({
                createAccount: !user.uid,
                values: {
                    name: values.name,
                    email: values.email,
                    phone: values.phone,
                    topic: values.topic,
                    message: values.message,
                    urgent: values.urgent,
                    communityName: community.name,
                    communityUrl: community.nameId,
                },
            });
        },
    });

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="mt-0 sm:mt-6 sm:mb-0 px-4 sm:px-8">
                <TextFieldFormik
                    formik={formik}
                    id="name"
                    name="name"
                    label="Full Name"
                    fullwidth
                    inputClassName="bg-gray-100"
                />

                <TextFieldFormik
                    formik={formik}
                    id="email"
                    type="email"
                    name="email"
                    label="Email"
                    fullwidth
                    className="mt-2"
                />

                <TextFieldFormik
                    formik={formik}
                    id="phone"
                    name="phone"
                    label="Mobile Number"
                    fullwidth
                    inputClassName="bg-gray-100"
                    className="mt-2"
                />

                <TextFieldFormik
                    formik={formik}
                    select
                    id="topic"
                    name="topic"
                    label="Question Topic"
                    options={topics}
                    fullwidth
                    inputClassName="bg-gray-100"
                    className="mt-2"
                />

                <TextAreaFormik
                    formik={formik}
                    id="message"
                    name="message"
                    label="Message"
                    fullwidth
                    inputClassName="bg-gray-100"
                    className="mt-2"
                    textareaClassName="bg-gray-100"
                />

                <div className="mt-4 flex justify-between">
                    <Button className="" type="submit">
                        Submit Question{user.uid ? "" : " and Create Account"}
                    </Button>
                    <CheckboxFormik
                        formik={formik}
                        id="urgent"
                        name="urgent"
                        label="Urgent Request"
                    />
                </div>
            </div>
        </form>
    );
};

const AskQuestionComplete = ({ community, email }) => {
    const paidPlan = community.plan && community.plan.startsWith("Paid");

    return (
        <div className="mt-6 mb-4 px-16">
            <p className="text-lg text-center">Thank you for your inquiry about</p>

            <p className="mt-2 text-xl text-primary-500 font-bold text-center">
                {community.name}
            </p>

            <p className="mt-4 text-center">
                {paidPlan
                    ? "The community director or staff should reply to you within 2 days."
                    : "A Purple Door Finders representative will provide you with the information you requested within 2 days."}
            </p>

            <hr className="mt-4 mx-24 border-black" />

            <p className="mt-8 text-sm font-bold text-center">
                {paidPlan
                    ? "If you do not hear from the community, please contact "
                    : "If you have any further questions, please contact "}

                <a
                    href="mailto:support@purpledoorfinders.com"
                    className="text-primary-500 underline hover:font-bold"
                >
                    support@purpledoorfinders.com
                </a>
            </p>

            {email ? <div dangerouslySetInnerHTML={{ __html: email }} /> : null}
        </div>
    );
};

export default AskQuestion;
