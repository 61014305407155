import React from "react";

export const RadioGroup = ({
    id,
    name,
    label,
    value,
    onChange,
    options,
    helperText = "",
    error = false,
    dirty = false,
    className = "",
    inputClassName = "",
}) => {
    const handleChange = (_value) => {
        onChange({ target: { name, value: _value } });
    };

    return (
        <div className={className}>
            <div>
                {options.map((option) => (
                    <div key={option.value} className="flex items-center">
                        <input
                            id={id + "." + option.value}
                            name={name}
                            type="checkbox"
                            checked={option.value === value}
                            onChange={() => handleChange(option.value)}
                            className={"cursor-pointer " + inputClassName}
                        />
                        {option.name ? (
                            <label
                                htmlFor={id + "." + option.value}
                                className="ml-3 text-sm font-semibold text-gray-900 cursor-pointer"
                            >
                                {option.name}
                            </label>
                        ) : null}
                    </div>
                ))}
            </div>

            {helperText ? (
                <div
                    className={`text-sm ${
                        error
                            ? "px-2 py-1 bg-red-500 rounded text-white font-bold"
                            : "text-gray-700"
                    }`}
                >
                    {helperText}
                </div>
            ) : null}
        </div>
    );
};

export const RadioGroupFormik = ({
    id,
    name,
    label,
    formik,
    options,
    required,
    disabled,
    className = "",
    inputClassName = "",
}) => {
    const handleChange = (e) => {
        formik.handleChange(e);
    };

    return (
        <RadioGroup
            id={id}
            name={name}
            label={label}
            value={formik.values[name]}
            onChange={handleChange}
            options={options}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            dirty={formik.initialValues[name] !== formik.values[name]}
            required={required}
            disabled={disabled}
            className={className}
            inputClassName={inputClassName}
        />
    );
};
