import React from "react";

const Checkbox = ({ id, name, label, value, onChange, inputClassName = "" }) => {
    return (
        <div className="px-2 py-1 flex items-center rounded hover:bg-gray-200">
            <input
                id={id}
                name={name}
                type="checkbox"
                checked={value}
                value={value}
                onChange={onChange}
                className={inputClassName}
            />
            <label
                htmlFor={id}
                className="ml-3 text-sm font-semibold text-gray-900 cursor-pointer"
            >
                {label}
            </label>
        </div>
    );
};

export const CheckboxFormik = ({
    id,
    name,
    label,
    formik,
    onChange,
    inputClassName = "",
}) => {
    const handleChange = (e) => {
        formik.handleChange({
            target: { name, value: e.target.checked, checked: e.target.checked },
        });

        if (onChange) {
            onChange(e);
        }
    };

    return (
        <Checkbox
            id={id}
            name={name}
            label={label}
            value={formik.values[name]}
            onChange={handleChange}
            inputClassName={inputClassName}
        />
    );
};

export default Checkbox;
