export const renameStatus = (status) => {
    if (status === "Available Now") {
        //return "Available";
        return "Vacancy";
    } else if (status === "Available") {
        //return "Available";
        return "Vacancy";
    } else if (status === "Available Soon") {
        return "Coming Soon";
    } else {
        return status;
    }
};
