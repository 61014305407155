import React from "react";

import { XIcon } from "@heroicons/react/outline";

import { IconButton } from "./IconButton";

const TextField = ({
    id,
    name,
    type = "text",
    select = false,
    value,
    onChange,
    label,
    placeHolder = "",
    helperText = "",
    error = false,
    dirty = false,
    touched = false,
    required = false,
    disabled = false,
    fullwidth = false,
    className = "",
    inputClassName = "",
    labelClassName = "",
    children,
    options,
    maxLength,
    accept,
    InputComponent,
    autoFocus,
}) => {
    id = id ? id : name;
    label = required ? label + " *" : label;

    const handleClear = (e) => {
        e.stopPropagation();
        e.preventDefault();
        onChange({ target: { name, value: null } });
    };

    /**
     * We used to show dirty state on the label, but it was confusing for users.
     * <label
            htmlFor={id}
            className={`block mb-1 ml-0 ${
                dirty
                    ? "text-amber-500 "
                    : disabled
                    ? "text-gray-400 "
                    : "text-primary-500 "
            } text-sm font-bold ${labelClassName}`}
        >
     */

    const isRequired =
        error && helperText && helperText.toLowerCase().endsWith("is required");

    if (select) {
        return (
            <div className={"relative " + className}>
                <label
                    htmlFor={id}
                    className={`flex justify-between items-center  mb-1 ml-0 ${
                        disabled ? "text-gray-400 " : "text-primary-500 "
                    } text-sm font-bold ${labelClassName}`}
                >
                    <span>{label}</span>
                    {isRequired ? (
                        <span className="text-red-500 font-bold">* Required</span>
                    ) : null}
                </label>
                <select
                    id={id}
                    name={name}
                    value={value ? value : ""}
                    onChange={onChange}
                    required={required}
                    disabled={disabled}
                    className={`relative ${fullwidth ? "w-full" : "w-[196px]"} px-3 py-1 ${
                        error ? "bg-red-200 border-2 border-red-500" : "bg-gray-200"
                    } 
                        ${inputClassName}`}
                >
                    <option key={""} value={""} disabled>
                        select...
                    </option>
                    {options
                        ? options.map((t) => (
                              <option key={t.value} value={t.value}>
                                  {t.name}
                              </option>
                          ))
                        : children}
                </select>

                {value ? (
                    <IconButton className="absolute top-6 right-7" onClick={handleClear}>
                        <XIcon className="w-5 h-5" />
                    </IconButton>
                ) : null}

                {helperText && !isRequired ? (
                    <div
                        className={`mt-1 text-sm ${
                            error
                                ? "px-2 py-1 bg-red-500 rounded text-white font-bold"
                                : "text-gray-700"
                        }`}
                    >
                        {helperText}
                    </div>
                ) : null}
            </div>
        );
    }

    /*if (InputComponent === undefined) {
        InputComponent = (props) => <input {...props} />;
    }*/

    return (
        <div className={className}>
            <label
                htmlFor={id}
                className={`flex justify-between items-center mb-1 ml-0 ${
                    disabled ? "text-gray-400 " : "text-primary-500 "
                } text-sm font-bold ${labelClassName}`}
            >
                <span>{label}</span>
                {isRequired ? (
                    <span className="text-red-500 font-bold">* Required</span>
                ) : null}
            </label>

            {InputComponent ? (
                <InputComponent
                    id={id}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeHolder}
                    required={required}
                    disabled={disabled}
                    maxLength={maxLength}
                    accept={accept}
                    className={`${fullwidth ? "w-full" : ""} px-3 py-1 ${
                        error ? "bg-red-200 border-2 border-red-500" : "bg-gray-200"
                    } ${disabled ? "text-gray-500" : ""}
                    ${inputClassName}`}
                />
            ) : (
                <input
                    autoFocus={autoFocus}
                    id={id}
                    type={type}
                    name={name}
                    value={value}
                    onChange={onChange}
                    placeholder={placeHolder}
                    required={required}
                    disabled={disabled}
                    maxLength={maxLength}
                    accept={accept}
                    className={`${fullwidth ? "w-full" : ""} px-3 py-1 ${
                        error ? "bg-red-200 border-2 border-red-500" : "bg-gray-200"
                    } ${disabled ? "text-gray-500" : ""}
                    ${inputClassName}`}
                />
            )}

            {helperText && !isRequired ? (
                <div
                    className={`mt-1 text-sm ${
                        error
                            ? "px-2 py-1 bg-red-500 rounded text-white font-bold"
                            : "text-gray-700"
                    }`}
                >
                    {helperText}
                </div>
            ) : null}
        </div>
    );
};

export default TextField;
