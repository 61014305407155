import React, { useState, useEffect } from "react";
import Image from "next/legacy/image";
import Link from "@/utils/link";

import { Dialog, Tab } from "@headlessui/react";
import { XIcon, ChevronLeftIcon, ChevronRightIcon } from "@heroicons/react/outline";

import { IconButton } from "../ui/Button";

import { useWindowSize } from "../../utils/hooks";
import { imageLoader, removeExt, PdImage } from "@/utils/image";

function normalizeYouTubeUrl(url) {
    if (!url) {
        return "";
    }

    const videoIdRegex =
        /(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|.*[?&]v=))([\w-]{11})(?:[?&]|$)/;
    const match = url.match(videoIdRegex);

    if (match && match[1]) {
        return `https://www.youtube.com/embed/${match[1]}`;
    } else {
        return url;
    }
}

const CommunityDialogGallery = ({ open, onClose, community, initialTab = 0 }) => {
    const { height } = useWindowSize();
    const [selectedId, setSelectedId] = useState(0);

    const scrollStyle = { height: height ? height - 160 - 60 : "100%" };

    let images = [];
    let videos = [];
    if (community.media) {
        images = community.media
            .filter((m) => m.mediaType !== "Video" && m.mediaType !== "Document")
            .map((m, index) => ({
                ...m,
                _id: index,
                src: m.url,
                type: m.type,
            }));

        videos = community.media.filter((m) => m.mediaType === "Video");
    }

    const handleSelect = (id) => {
        setSelectedId(id);
    };

    const handleCommunityPdfListing = () => {
        window.open("/community-mls/" + community.nameId, "_blank");
    };

    const tabs = ["Images", "Videos", "Community Files"];

    return (
        <Dialog
            open={open}
            onClose={onClose}
            className="fixed z-40 top-16 bottom-8 left-3 right-3 sm:top-20 sm:bottom-6 sm:left-6 sm:right-6 overflow-y-auto"
        >
            <div className="flex items-center justify-center">
                <Dialog.Overlay className="fixed inset-0 bg-black opacity-60" />

                <div className="z-50 bg-gray-100 w-full border rounded-xl overflow-hidden">
                    <Tab.Group
                        as="div"
                        defaultIndex={initialTab}
                        className="w-full bg-gray-100 rounded-t border-b border-gray-200"
                    >
                        <Tab.List className="px-6 py-4 flex justify-center gap-x-4 sm:gap-x-16">
                            {tabs.map((tab) => (
                                <Tab key={tab} as={React.Fragment}>
                                    {({ selected }) => (
                                        <button
                                            className={
                                                selected
                                                    ? "pb-1 text-primary-500 border-b-4 border-primary-400"
                                                    : "pb-1 text-primary-400 border-b-4 border-transparent"
                                            }
                                        >
                                            {tab}
                                        </button>
                                    )}
                                </Tab>
                            ))}

                            <div className="mb-2">
                                <IconButton onClick={onClose}>
                                    <XIcon className="w-6 h-6" />
                                </IconButton>
                            </div>
                        </Tab.List>

                        <Tab.Panels className="w-full pb-4 sm:pb-0">
                            <Tab.Panel className="w-full">
                                <div className="w-full flex flex-col justify-between">
                                    <div className="hidden sm:block border-b border-gray-300">
                                        <div className="pl-6 pr-2 py-2 flex justify-between items-center bg-gray-200">
                                            <div className="space-x-6">
                                                <ScrollButton title="Community" />
                                                <ScrollButton title="Amenities" />
                                                <ScrollButton title="Activities" />
                                                <ScrollButton title="Dining" />
                                                <ScrollButton title="Rooms" />
                                            </div>
                                        </div>
                                    </div>

                                    <div
                                        className="w-full flex-grow flex justify-between sm:gap-x-6"
                                        style={scrollStyle}
                                    >
                                        <LeftGallery
                                            community={community}
                                            images={images}
                                            onSelect={handleSelect}
                                            selectedId={selectedId}
                                        />
                                        <RightPreview
                                            community={community}
                                            images={images}
                                            onSelect={handleSelect}
                                            selectedId={selectedId}
                                            height={height}
                                        />
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel className="flex justify-center items-center">
                                <div
                                    className="mt-8 pb-32 px-4"
                                    style={{ maxHeight: height - 200 }}
                                >
                                    <div className="grid grid-cols-1 sm:grid-cols-2 gap-x-16 gap-y-8">
                                        {videos.map((m) => (
                                            <div key={m.url}>
                                                <iframe
                                                    width="480"
                                                    height="270"
                                                    src={normalizeYouTubeUrl(m.url)}
                                                    title="YouTube video player"
                                                    frameBorder="0"
                                                    allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                                    allowFullScreen
                                                ></iframe>

                                                <h3 className="mt-4 text-center font-bold">
                                                    {m.title}
                                                </h3>
                                            </div>
                                        ))}

                                        {videos.length === 0 ? (
                                            <div className="text-xl text-center">
                                                No Available Videos
                                            </div>
                                        ) : null}
                                    </div>
                                </div>
                            </Tab.Panel>

                            <Tab.Panel className="flex justify-center items-center">
                                <div
                                    className="mt-8 pb-32"
                                    style={{ maxHeight: height - 200 }}
                                >
                                    <div className="flex flex-col sm:flex-row space-y-8 sm:space-y-0 sm:space-x-32">
                                        <div className="flex flex-col space-y-4 text-primary-500 text-lg">
                                            <h3 className="mb-4 text-2xl font-bold">
                                                Community Files
                                            </h3>

                                            <div>
                                                <button
                                                    onClick={handleCommunityPdfListing}
                                                    className="hover:underline"
                                                >
                                                    Community PDF Listing
                                                </button>
                                            </div>

                                            {community.brochureUrl ? (
                                                <Link
                                                    href={community.brochureUrl}
                                                    rel="nofollow"
                                                >
                                                    <a
                                                        rel="nofollow"
                                                        className="hover:underline"
                                                    >
                                                        Brochure
                                                    </a>
                                                </Link>
                                            ) : null}

                                            {community.careInfoUrl ? (
                                                <Link
                                                    href={community.careInfoUrl}
                                                    rel="nofollow"
                                                >
                                                    <a
                                                        rel="nofollow"
                                                        className="hover:underline"
                                                    >
                                                        Care Information
                                                    </a>
                                                </Link>
                                            ) : null}

                                            {community.menuUrl ? (
                                                <Link
                                                    href={community.menuUrl}
                                                    rel="nofollow"
                                                >
                                                    <a
                                                        rel="nofollow"
                                                        className="hover:underline"
                                                    >
                                                        Menu
                                                    </a>
                                                </Link>
                                            ) : null}

                                            {community.calendarUrl ? (
                                                <Link
                                                    href={community.calendarUrl}
                                                    rel="nofollow"
                                                >
                                                    <a
                                                        rel="nofollow"
                                                        className="hover:underline"
                                                    >
                                                        Activity Calendar
                                                    </a>
                                                </Link>
                                            ) : null}

                                            {community.upcomingEventUrl ? (
                                                <Link
                                                    href={community.upcomingEventUrl}
                                                    rel="nofollow"
                                                >
                                                    <a
                                                        rel="nofollow"
                                                        className="hover:underline"
                                                    >
                                                        Upcoming Events
                                                    </a>
                                                </Link>
                                            ) : null}

                                            {community.supportGroupsUrl ? (
                                                <Link
                                                    href={community.supportGroupsUrl}
                                                    rel="nofollow"
                                                >
                                                    <a
                                                        rel="nofollow"
                                                        className="hover:underline"
                                                    >
                                                        Support Groups
                                                    </a>
                                                </Link>
                                            ) : null}
                                        </div>

                                        <div className="flex flex-col items-start space-y-4 text-primary-500 text-lg">
                                            <h3 className="mb-4 text-2xl font-bold">
                                                Additional Community Files
                                            </h3>

                                            {community.media
                                                ? community.media
                                                      .filter(
                                                          (m) => m.mediaType === "Document"
                                                      )
                                                      .map((d) => (
                                                          <Link
                                                              key={d.url}
                                                              href={d.url}
                                                              rel="nofollow"
                                                          >
                                                              <a
                                                                  rel="nofollow"
                                                                  className="hover:underline"
                                                              >
                                                                  {d.title}
                                                              </a>
                                                          </Link>
                                                      ))
                                                : null}
                                        </div>
                                    </div>
                                </div>
                            </Tab.Panel>
                        </Tab.Panels>
                    </Tab.Group>
                </div>
            </div>
        </Dialog>
    );
};

const ScrollButton = ({ title }) => {
    //const { zoom } = useContext(LayoutContext);

    return (
        <button
            className="text-primary-500"
            onClick={() => {
                const e = document.getElementById(
                    "id-type-" + title.toLowerCase().replaceAll(" ", "-")
                );

                if (e) {
                    const gallery = document.getElementById("id-left-gallery");

                    // Change the element scroll:
                    // new_scrollTop = old_scrollTop + e.top - 100
                    gallery.scroll({
                        top: gallery.scrollTop + e.getBoundingClientRect().top - 160 - 60,
                        behavior: "smooth",
                    });
                }
            }}
        >
            {title}
        </button>
    );
};

const SideImage = ({ image, onSelect, full, selectedId }) => {
    let border = "";

    if (image._id === selectedId) {
        border = " box-border border-4 border-primary-500";
    }

    if (full) {
        return (
            <button
                onClick={() => onSelect(image._id)}
                className="w-full h-48 sm:col-span-2"
            >
                {/*<img
                    src={image.src}
                    alt={image.type}
                    className={"w-full h-48 object-cover rounded" + border}
                />*/}

                <div className={"relative w-full h-48 rounded" + border}>
                    <PdImage src={removeExt(image.src) + ".sm.jpg"} alt={image.type} />
                    {/*<Image
                        loader={imageLoader}
                        src={removeExt(image.src) + ".sm.jpg"}
                        alt={image.type}
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                        priority
                        unoptimized={false}
                    />*/}
                </div>
            </button>
        );
    } else {
        return (
            <button
                key={image.src}
                onClick={() => onSelect(image._id)}
                className="w-full h-48 sm:h-32"
            >
                {/*<img
                    src={image.src}
                    alt={image.type}
                    className={"w-full h-32 object-cover rounded" + border}
                />*/}

                <div className={"relative w-full h-48 sm:h-32 rounded" + border}>
                    <PdImage src={removeExt(image.src) + ".sm.jpg"} alt={image.type} />
                    {/*<Image
                        loader={imageLoader}
                        src={removeExt(image.src) + ".sm.jpg"}
                        alt={image.type}
                        layout="fill"
                        objectFit="cover"
                        objectPosition="center"
                        priority
                        unoptimized={false}
                    />*/}
                </div>
            </button>
        );
    }
};

const ImagesOfType = ({ images, onSelect, type, selectedId }) => {
    return (
        <>
            <h3
                id={"id-type-" + type.toLowerCase()}
                className="sm:col-span-2 text-lg font-bold text-gray-900"
            >
                {type}
            </h3>
            {images
                .filter((img) => {
                    if (type === "Community") {
                        return img.type === "Community" || img.type === "Main";
                    } else if (type === "Amenities") {
                        return img.type === "Amenity";
                    } else if (type === "Activities") {
                        return img.type === "Activity";
                    } else if (type === "Dining") {
                        return img.type === "Dining" || img.type === "Dinning";
                    } else if (type === "Rooms") {
                        return img.type === "Unit" || img.type === "Floor Plan";
                    } else {
                        return img.type === type;
                    }
                })
                .map((image, index) => {
                    return (
                        <SideImage
                            key={image._id}
                            image={image}
                            onSelect={onSelect}
                            selectedId={selectedId}
                            full={index % 3 === 2}
                        />
                    );
                })}
        </>
    );
};

const LeftGallery = ({ community, images, onSelect, selectedId }) => {
    return (
        <div
            id="id-left-gallery"
            className="px-4 sm:px-6 sm:py-2 w-full sm:w-[408px] sm:bg-gray-200 flex-shrink-0 grid grid-cols-1 sm:grid-cols-2 gap-4 overflow-y-scroll"
        >
            <ImagesOfType
                images={images}
                onSelect={onSelect}
                selectedId={selectedId}
                type="Community"
            />
            <ImagesOfType
                images={images}
                onSelect={onSelect}
                selectedId={selectedId}
                type="Amenities"
            />
            <ImagesOfType
                images={images}
                onSelect={onSelect}
                selectedId={selectedId}
                type="Activities"
            />
            <ImagesOfType
                images={images}
                onSelect={onSelect}
                selectedId={selectedId}
                type="Dining"
            />
            <ImagesOfType
                images={images}
                onSelect={onSelect}
                selectedId={selectedId}
                type="Rooms"
            />
        </div>
    );
};

const RightPreview = ({ community, images, selectedId, onSelect, height }) => {
    const imageIndex = images.findIndex((img) => img._id === selectedId);
    const image = images[imageIndex];
    const address = community.contact[0].address[0];

    const handleLeft = () => {
        if (imageIndex > 0) {
            onSelect(images[imageIndex - 1]._id);
        }
    };

    const handleRight = () => {
        if (imageIndex < images.length - 1) {
            onSelect(images[imageIndex + 1]._id);
        }
    };

    if (image) {
        return (
            <>
                <div className="hidden sm:block flex-grow">
                    <div className="w-full h-full mt-0 flex flex-col justify-between">
                        <div className="flex-grow-0">
                            <div className="mt-4 flex justify-center">
                                <h2 className="text-2xl">{community.name}</h2>
                            </div>

                            {address ? (
                                <div className="mt-2 flex justify-center">
                                    <h2 className="text-md text-gray-700">
                                        {address.street},{" "}
                                        <span className="text-gray-800">
                                            {address.city}
                                        </span>
                                        ,{" "}
                                        <span className="text-gray-800">
                                            {address.state}
                                        </span>{" "}
                                        {address.postalCode}
                                    </h2>
                                </div>
                            ) : null}
                        </div>

                        <div>
                            <div
                                className="relative mt-0 flex-2 flex justify-center"
                                style={{ height: height - 500 }}
                            >
                                {/*<img
                                src={image.src}
                                alt={image.type}
                                className="object-cover rounded-xl"
                                style={{ width: "100%", maxWidth: "100%" }}
                            />*/}

                                <PdImage src={removeExt(image.src)} alt={image.type} />

                                {/*<Image
                                loader={imageLoader}
                                src={removeExt(image.src)}
                                alt={image.type}
                                layout="fill"
                                objectFit="contain"
                                objectPosition="center"
                                priority
                                unoptimized={false}
                            />*/}
                            </div>

                            <div className="mt-2 font-bold text-center">{image.title}</div>

                            <div className="mt-2 text-sm">{image.description}</div>
                        </div>

                        <div className="mb-4 flex items-center justify-center">
                            <div className="p-0 flex space-x-4 items-center border-2 border-gray-600 rounded-full">
                                <div className="pr-2 border-r border-gray-400">
                                    <IconButton onClick={handleLeft}>
                                        <ChevronLeftIcon className="w-6 h-6" />
                                    </IconButton>
                                </div>

                                <div>
                                    {imageIndex + 1} / {images.length}
                                </div>

                                <div className="pl-2 border-l border-gray-400">
                                    <IconButton onClick={handleRight}>
                                        <ChevronRightIcon className="w-6 h-6" />
                                    </IconButton>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div
                    className="pt-8 w-full h-full sm:hidden absolute inset-0 flex justify-between items-center bg-black bg-opacity-80"
                    style={
                        {
                            /*maxHeight: "75%"*/
                        }
                    }
                >
                    <div className="relative w-full h-full">
                        <div
                            className="relative flex justify-center"
                            style={
                                {
                                    /*height: height - 500*/
                                }
                            }
                        >
                            <PdImage src={removeExt(image.src)} alt={image.type} />
                        </div>

                        <div className="absolute top-2 right-2">
                            <button
                                onClick={() => onSelect(null)}
                                className="px-4 py-2 text-base bg-primary-500 text-white rounded-lg"
                            >
                                Close Image
                            </button>
                        </div>
                    </div>
                </div>
            </>
        );
    } else {
        return null;
    }
};

export default CommunityDialogGallery;
