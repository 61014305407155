import React from "react";

import { XIcon } from "@heroicons/react/outline";

import { IconButton } from "./IconButton";

const TextArea = ({
    id,
    name,
    value,
    onChange,
    label,
    placeHolder = "",
    helperText = "",
    error = false,
    dirty = false,
    required = false,
    disabled = false,
    fullwidth = false,
    className,
    textareaClassName,
    labelClassName,
    rows = 4,
    maxLength,
    description = "",
}) => {
    id = id ? id : name;
    label = required ? label + " *" : label;

    return (
        <div className={className}>
            <label
                htmlFor={id}
                className={`block mb-1 ml-0 ${
                    disabled ? "text-gray-400 " : "text-primary-500 "
                } text-sm font-bold ${labelClassName}`}
            >
                {label}
            </label>

            {description ? (
                <div className="mt-1 mb-1 text-gray-600">{description}</div>
            ) : null}

            <textarea
                id={id}
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeHolder}
                required={required}
                rows={rows}
                maxLength={maxLength}
                className={`${fullwidth ? "w-full" : ""} px-3 py-1 ${
                    error ? "bg-red-200 border-2 border-red-500" : "bg-gray-200"
                }
                    ${textareaClassName}`}
            />

            {helperText ? (
                <div
                    className={`text-sm ${
                        error
                            ? "px-2 py-1 bg-red-500 rounded text-white font-bold"
                            : "text-gray-700"
                    }`}
                >
                    {helperText}
                </div>
            ) : null}
        </div>
    );
};

export const TextAreaFormik = ({
    id,
    name,
    label,
    formik,
    required,
    disabled,
    fullwidth,
    className,
    rows,
    maxLength,
    description,
}) => {
    return (
        <TextArea
            id={id}
            name={name}
            label={label}
            value={formik.values[name]}
            onChange={formik.handleChange}
            error={Boolean(formik.errors[name])}
            helperText={formik.errors[name]}
            dirty={formik.initialValues[name] !== formik.values[name]}
            required={required}
            disabled={disabled}
            fullwidth={fullwidth}
            className={className}
            rows={rows}
            maxLength={maxLength}
            description={description}
        />
    );
};

export default TextArea;
