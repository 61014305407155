import React, { useState, useEffect } from "react";
import moment from "moment";

import {
    Page,
    Text,
    View,
    Document,
    Image,
    StyleSheet,
    Font,
    PDFViewer,
    pdf,
} from "@react-pdf/renderer";

import RobotoMedium from "../../public/fonts/Roboto-Medium.ttf";
import RobotoBold from "../../public/fonts/Roboto-Bold.ttf";

import { getCommunityTypesText } from "./CommunityUtils";
import { removeExt } from "@/utils/image";
import { checkUnitAvailability } from "./CommunityTemplates";
import { checkSpecialOffer, getAvailability } from "../home/CommunityCard";

Font.register({
    family: "Roboto",
    fonts: [
        {
            src: RobotoMedium,
        },
        {
            src: RobotoBold,
            fontWeight: "800",
        },
    ],
});

const colors = {
    textLight: "#444",
    primary: "#59237F",
    bgLightGray: "#e3e4e6",
};

// Create styles
const styles = StyleSheet.create({
    page: {
        //flexDirection: "row",
        backgroundColor: "#fff",
    },
    section: {
        margin: 32,
        //padding: 10,
        flexGrow: 1,
        fontSize: 12,
    },
    title: {
        fontSize: 16,
        fontWeight: "bold",
        //color: "green",
        fontFamily: "Roboto",
    },
});

const getMainImageUrl = (community) => {
    let mainImageUrl = "";
    if (community.media) {
        const mainImage = community.media.find((m) => m.type === "Main");
        if (mainImage) {
            mainImageUrl = mainImage.url;
        }
    }

    return mainImageUrl;
};

const NotOnboardedCommunity = ({ community, phone }) => {
    const mainImageUrl = getMainImageUrl(community);

    console.log("mainImageUrl", mainImageUrl, phone);

    const address = community.contact ? community.contact[0].address[0] : {};

    let formattedPhone = "";
    if (phone) {
        formattedPhone = formatPhoneNumber(phone);
    }

    const fullAddress =
        address?.street && address?.city && address?.state && address?.postalCode
            ? address.street +
              ", " +
              address.city +
              ", " +
              address.state +
              " " +
              address.postalCode
            : "";

    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <View
                    style={{
                        position: "relative",
                        margin: 32,
                        fontSize: 12,
                        fontFamily: "Roboto",
                    }}
                >
                    <View>
                        <Text style={styles.title}>{community.name}</Text>

                        <View
                            style={{
                                marginTop: 4,
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                            }}
                        >
                            <Text style={{ color: colors.textLight }}>{fullAddress}</Text>
                            <Text style={{ fontWeight: "bold" }}>{formattedPhone}</Text>
                        </View>
                        <Text style={{ marginTop: 8, color: colors.primary, fontSize: 10 }}>
                            {getCommunityTypesText(community.facilityType).toUpperCase()}{" "}
                            &bull;{" "}
                            {community.numberOfUnits > 100
                                ? "100+"
                                : community.numberOfUnits}{" "}
                            Units
                        </Text>
                    </View>
                </View>

                <View style={{ marginRight: 32, marginLeft: 32 }}>
                    <Notes />
                </View>

                <Footer />
            </Page>
        </Document>
    );
};

// format phone number string to XXX-XXX-XXXX
const formatPhoneNumber = (num) => {
    const cleaned = ("" + num).replace(/\D/g, "");
    const match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return "(" + match[1] + ") " + match[2] + "-" + match[3];
    }
    return num;
};

// Create Document Component
const OnboardedCommunity = ({ community }) => {
    const mainImageUrl = getMainImageUrl(community);

    console.log("mainImageUrl", mainImageUrl);

    const availability = getAvailability(community);

    const address = community.contact[0].address[0];

    let phone = "";
    if (community.contact[0].phone && community.contact[0].phone.length > 0) {
        phone = formatPhoneNumber(community.contact[0].phone[0].number);
    }

    const fullAddress =
        address.street +
        ", " +
        address.city +
        ", " +
        address.state +
        " " +
        address.postalCode;

    let amenities = [];

    if (community.amenities) {
        amenities = [...amenities, ...community.amenities.split(";")];
    }

    if (community.amenitiesTags) {
        amenities = [...amenities, ...community.amenitiesTags.split(";")];
    }

    let activities = [];

    if (community.activities) {
        activities = [...activities, ...community.activities.split(";")];
    }

    if (community.activitiesTags) {
        activities = [...activities, ...community.activitiesTags.split(";")];
    }

    let dining = [];

    if (community.dining) {
        dining = [...dining, ...community.dining.split(";")];
    }

    if (community.diningTags) {
        dining = [...dining, ...community.diningTags.split(";")];
    }

    let care = [];

    if (community.careOptions) {
        care = [...care, ...community.careOptions.split(";")];
    }

    if (community.careTags) {
        care = [...care, ...community.careTags.split(";")];
    }

    let careGiver = [];
    if (community.careNurse) {
        careGiver = [...careGiver, ...community.careNurse.split(";")];
    }

    if (community.careGiverTags) {
        careGiver = [...careGiver, ...community.careGiverTags.split(";")];
    }

    const paidPlan = community.plan && community.plan.startsWith("Paid");

    return (
        <Document>
            <Page size="LETTER" style={styles.page}>
                <Header />

                <View
                    style={{
                        position: "relative",
                        margin: "0 32px 32px 32px",
                        fontSize: 12,
                        fontFamily: "Roboto",
                    }}
                >
                    <View>
                        <Text style={styles.title}>{community.name}</Text>
                    </View>
                    <View
                        style={{
                            marginTop: 4,
                            flexDirection: "row",
                            justifyContent: "space-between",
                            alignItems: "center",
                        }}
                    >
                        <Text style={{ color: colors.textLight }}>{fullAddress}</Text>
                        <Text style={{ fontWeight: "bold" }}>{phone}</Text>
                    </View>

                    <View style={{ marginTop: 4, flexDirection: "row" }}>
                        {community.licenseNumber ? (
                            <View style={{ flexDirection: "row" }}>
                                <Text style={{ color: colors.textLight }}>
                                    State License:{" "}
                                </Text>
                                <Text>{community.licenseNumber}</Text>
                                {community.licenseNumber2 ? <Text>, </Text> : null}
                            </View>
                        ) : null}

                        {community.licenseNumber2 ? (
                            <View style={{ flexDirection: "row" }}>
                                <Text style={{ color: colors.textLight }}>
                                    Federal License:{" "}
                                </Text>
                                <Text>{community.licenseNumber2}</Text>
                            </View>
                        ) : null}
                    </View>

                    <View
                        style={{
                            marginTop: 8,
                            flexDirection: "row",
                        }}
                    >
                        <Text style={{ color: colors.primary, fontSize: 10 }}>
                            {getCommunityTypesText(community.facilityType).toUpperCase()}{" "}
                        </Text>
                    </View>

                    <View style={{ marginTop: 8, flexDirection: "row" }}>
                        {community.onboarded ? (
                            <Text
                                style={{
                                    padding: "4px 8px 4px 8px",
                                    fontSize: 10,
                                    backgroundColor: colors.primary,
                                    borderRadius: "100%",
                                    color: "white",
                                    marginRight: 4,
                                }}
                            >
                                CLAIMED
                            </Text>
                        ) : null}

                        {paidPlan && checkSpecialOffer(community) ? (
                            <Text
                                style={{
                                    padding: "4px 8px 4px 8px",
                                    fontSize: 10,
                                    backgroundColor: colors.primary,
                                    borderRadius: "100%",
                                    color: "white",
                                    marginRight: 4,
                                }}
                            >
                                SPECIAL INCENTIVE
                            </Text>
                        ) : null}

                        {availability === "Available Now" ? (
                            <Text
                                style={{
                                    padding: "4px 8px 4px 8px",
                                    fontSize: 10,
                                    backgroundColor: "#16a34a",
                                    borderRadius: "100%",
                                    color: "white",
                                    marginRight: 4,
                                }}
                            >
                                VACANCY
                            </Text>
                        ) : null}

                        {paidPlan ? (
                            <Text
                                style={{
                                    padding: "4px 8px 4px 8px",
                                    fontSize: 10,
                                    backgroundColor: colors.primary,
                                    borderRadius: "100%",
                                    color: "white",
                                    marginRight: 4,
                                }}
                            >
                                PREMIUM
                            </Text>
                        ) : null}

                        {availability === "Coming Soon" ? (
                            <Text
                                style={{
                                    padding: "4px 8px 4px 8px",
                                    fontSize: 10,
                                    backgroundColor: "#16a34a",
                                    borderRadius: "100%",
                                    color: "white",
                                }}
                            >
                                COMING SOON
                            </Text>
                        ) : null}

                        {paidPlan && community.preLeasing ? (
                            <Text
                                style={{
                                    padding: "4px 8px 4px 8px",
                                    fontSize: 10,
                                    backgroundColor: "#eab308",
                                    borderRadius: "100%",
                                    color: "white",
                                    marginRight: 4,
                                }}
                            >
                                PRE-LEASING
                            </Text>
                        ) : null}
                    </View>

                    <View
                        style={{
                            marginTop: 8,
                            flexDirection: "row",
                            justifyContent: "space-between",
                        }}
                    >
                        <View style={{ width: "60%" }}>
                            <SectionGroup title="Community Deposits and Fees* (Deposits and fees may differ on individual units)">
                                <Section2Col title="Community Deposits and Fees">
                                    <UnorderedList>
                                        <ListItem>{community.deposit}</ListItem>
                                        <ListItem>{community.communityFee}</ListItem>
                                        <ListItem>{community.careFee}</ListItem>
                                        <ListItem>{community.secondPersonFee}</ListItem>
                                        <ListItem>{community.parkingFee}</ListItem>
                                    </UnorderedList>
                                </Section2Col>

                                <Section title="Pet Deposit and Fee">
                                    <UnorderedList>
                                        <ListItem>{community.petDeposit}</ListItem>
                                        <ListItem>{community.monthlyPetFee}</ListItem>
                                    </UnorderedList>
                                </Section>

                                {community.depositsAndFeesInfo ? (
                                    <Section3Col title="Additional Information for Community Deposits And Fees">
                                        <Text>{community.depositsAndFeesInfo}</Text>
                                    </Section3Col>
                                ) : null}
                            </SectionGroup>

                            <SectionGroup title="Community Details">
                                <Section title="Community">
                                    <UnorderedList>
                                        {community.yearBuilt ? (
                                            <ListItem>
                                                Built in {community.yearBuilt.split("-")[0]}
                                            </ListItem>
                                        ) : null}

                                        {community.numberOfUnits ? (
                                            <ListItem>
                                                {community.numberOfUnits > 100
                                                    ? "100+"
                                                    : community.numberOfUnits}{" "}
                                                Units
                                            </ListItem>
                                        ) : null}

                                        {community.numberOfFloors ? (
                                            <ListItem>
                                                {community.numberOfFloors} Floors
                                            </ListItem>
                                        ) : null}

                                        {community.numberOfElevators ? (
                                            <ListItem>
                                                {community.numberOfElevators} Elevators
                                            </ListItem>
                                        ) : null}

                                        {community.privatePay === true ? (
                                            <ListItem>Private Pay Available (?)</ListItem>
                                        ) : null}

                                        {community.washerDryer === true ? (
                                            <ListItem>Washer Dryer Available (?)</ListItem>
                                        ) : null}
                                    </UnorderedList>
                                </Section>

                                <Section title="Parking">
                                    <UnorderedList>
                                        {community.parking
                                            ? community.parking
                                                  .split(";")
                                                  .map((p) => (
                                                      <ListItem key={p}>{p}</ListItem>
                                                  ))
                                            : null}
                                    </UnorderedList>
                                </Section>

                                <Section
                                    title="Property"
                                    className="col-span-2 sm:col-span-1"
                                >
                                    <UnorderedList>
                                        {community.propertySmoking ? (
                                            <ListItem>
                                                Property Smoking -{" "}
                                                {community.propertySmoking}
                                            </ListItem>
                                        ) : null}

                                        {community.propertyManagerOnsite ? (
                                            <ListItem>
                                                Property Manager Onsite -{" "}
                                                {community.propertyManagerOnsite === "Yes"
                                                    ? "Yes"
                                                    : "No"}
                                            </ListItem>
                                        ) : null}

                                        {community.propertyMaintenanceOnsite ? (
                                            <ListItem>
                                                Property Maintenance Onsite -{" "}
                                                {community.propertyMaintenanceOnsite ===
                                                "Yes"
                                                    ? "Yes"
                                                    : "No"}
                                            </ListItem>
                                        ) : null}
                                    </UnorderedList>
                                </Section>

                                <Section title="Security">
                                    <UnorderedList>
                                        {community.security
                                            ? community.security
                                                  .split(";")
                                                  .map((p) => (
                                                      <ListItem key={p}>{p}</ListItem>
                                                  ))
                                            : null}
                                    </UnorderedList>
                                </Section>

                                {community.visitingHours ? (
                                    <Section title="Visiting Hours And Rules">
                                        <UnorderedList>
                                            <ListItem>{community.visitingHours}</ListItem>
                                        </UnorderedList>
                                    </Section>
                                ) : null}

                                {community.covidInfo ? (
                                    <Section title="Health and Covid-Related Information">
                                        <UnorderedList>
                                            <ListItem>{community.covidInfo}</ListItem>
                                        </UnorderedList>
                                    </Section>
                                ) : null}
                            </SectionGroup>

                            <SectionGroup title="Care Information">
                                {careGiver.length > 0 ? (
                                    <Section title="Care Giver">
                                        <UnorderedList>
                                            {careGiver.map((option) => (
                                                <ListItem key={option}>{option}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Section>
                                ) : null}

                                {community.languages ? (
                                    <Section title="Languages">
                                        <UnorderedList>
                                            <ListItem>{community.languages}</ListItem>
                                        </UnorderedList>
                                    </Section>
                                ) : null}

                                <Section title="Care Giver Hours">
                                    <UnorderedList>
                                        <ListItem>
                                            Care Giver Hours - {community.careNurseHours}
                                        </ListItem>
                                    </UnorderedList>
                                </Section>

                                {community.careLift ? (
                                    <Section title="Care Giver Lift">
                                        <UnorderedList>
                                            {community.careLift.split(";").map((option) => (
                                                <ListItem key={option}>{option}</ListItem>
                                            ))}
                                        </UnorderedList>
                                    </Section>
                                ) : null}

                                <Section2Col title="Additional Care Services">
                                    <UnorderedList
                                        style={{ flexDirection: "row", flexWrap: "wrap" }}
                                    >
                                        {care.map((option) => (
                                            <ListItem key={option} style={{ width: "50%" }}>
                                                {option}
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Section2Col>

                                {community.careInjections ? (
                                    <Section title="Injections">
                                        <UnorderedList>
                                            {community.careInjections
                                                .split(";")
                                                .map((option) => (
                                                    <ListItem key={option}>
                                                        {option}
                                                    </ListItem>
                                                ))}
                                        </UnorderedList>
                                    </Section>
                                ) : null}

                                {community.careInfo ? (
                                    <Section3Col title="Additional Care Information">
                                        <Text>{community.careInfo}</Text>
                                    </Section3Col>
                                ) : null}
                            </SectionGroup>

                            <SectionGroup title="Food and Dining">
                                <Section title="Food Preparation">
                                    <UnorderedList>
                                        {community.foodPreparation
                                            ? community.foodPreparation
                                                  .split(";")
                                                  .map((p) => (
                                                      <ListItem key={p}>{p}</ListItem>
                                                  ))
                                            : null}
                                    </UnorderedList>
                                </Section>

                                <Section title="Food options" className="sm:col-span-2">
                                    <UnorderedList>
                                        {community.foodOptions
                                            ? community.foodOptions
                                                  .split(";")
                                                  .map((p) => (
                                                      <ListItem key={p}>{p}</ListItem>
                                                  ))
                                            : null}
                                    </UnorderedList>
                                </Section>

                                <Section title="Dining" className="sm:col-span-2">
                                    <UnorderedList>
                                        {dining.map((p) => (
                                            <ListItem key={p}>{p}</ListItem>
                                        ))}
                                    </UnorderedList>
                                </Section>
                            </SectionGroup>

                            <SectionGroup title="Amenities">
                                <Section title="" width="100%">
                                    <UnorderedList
                                        style={{
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {amenities.map((p) => (
                                            <ListItem key={p} style={{ width: "33%" }}>
                                                {p}
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Section>
                            </SectionGroup>

                            <SectionGroup title="Activities" borderBottom>
                                <Section title="" width="100%">
                                    <UnorderedList
                                        style={{
                                            flexDirection: "row",
                                            flexWrap: "wrap",
                                            justifyContent: "space-between",
                                        }}
                                    >
                                        {activities.map((p) => (
                                            <ListItem key={p} style={{ width: "33%" }}>
                                                {p}
                                            </ListItem>
                                        ))}
                                    </UnorderedList>
                                </Section>
                            </SectionGroup>
                        </View>
                        <View style={{ width: "38%" }}>
                            {/*<Image
                                source={{
                                    //uri: "/images/community-portal1.jpg",
                                    //uri: "https://cdn.purpledoorfinders.com/acc-assisted-living-at-greenhaven-terrace-100020/c117cb36-9784-490d-84b5-c6aec9ae030d.md.jpg",
                                    //uri: "https://cdn.purpledoorfinders.com/senior-suites-of-autumn-green-at-wright-campus-101293/ccc7abcf-3e48-42a0-a67a-e0ac918e5d85.jpg",
                                    uri: "https://cdn.purpledoorfinders.com/acc-assisted-living-at-greenhaven-terrace-100020/783c73ed-7eed-4ba3-b2ab-e05239167488.md.jpg",
                                    method: "GET",
                                    headers: {
                                        "Access-Control-Allow-Origin": "*",
                                    },
                                    body: "",
                                }}
                            />*/}
                            <Image
                                source={{
                                    uri: "/api/getImage",
                                    method: "GET",
                                    headers: {
                                        imageUrl: removeExt(mainImageUrl) + ".md.jpg",
                                        //imageUrl:
                                        //    "https://cdn.purpledoorfinders.com/acc-assisted-living-at-greenhaven-terrace-100020/c117cb36-9784-490d-84b5-c6aec9ae030d.md.jpg",
                                    },
                                }}
                            />

                            <CommunityManagerCard community={community} />

                            <Notes />
                        </View>
                    </View>
                </View>

                <AvailableUnits community={community} />
                <Footer />
            </Page>
        </Document>
    );
};

const Header = () => {
    return <View fixed style={{ marginTop: 32 }}></View>;
};

const Footer = () => {
    return (
        <View
            fixed
            style={{
                position: "absolute",
                fontSize: 12,
                bottom: 22,
                left: 0,
                right: 0,
                textAlign: "center",
                color: "black",
                borderTop: "1px solid gray",
                marginLeft: 32,
                marginRight: 32,
                paddingTop: 8,
                marginTop: 8,
            }}
        >
            <View style={{ width: "100%" }}>
                <Image src="/images/pdf-footer.png" />
            </View>
        </View>
    );
};

const SectionGroup = ({ title, children, borderBottom = true }) => {
    return (
        <View
            wrap={false}
            style={{
                borderBottom: borderBottom ? "1px solid #ccc" : "",
                borderLeft: "1px solid #ccc",
                borderRight: "1px solid #ccc",
            }}
        >
            <Text
                style={{
                    borderTop: "1px solid #ccc",
                    borderBottom: "1px solid #ccc",
                    padding: 6,
                    backgroundColor: colors.bgLightGray,
                    fontSize: 10,
                    fontWeight: "bold",
                }}
            >
                {title}
            </Text>
            <View
                style={{
                    paddingLeft: 6,
                    paddingRight: 6,
                    paddingBottom: 6,
                    flexDirection: "row",
                    justifyContent: "space-between",
                    flexWrap: "wrap",
                }}
            >
                {children}
            </View>
        </View>
    );
};

const Section = ({ title, children, width = "33%" }) => {
    return (
        <View style={{ width, marginTop: 6 }}>
            <Text style={{ fontSize: 10, fontWeight: "bold", marginBottom: 4 }}>
                {title}
            </Text>
            <View style={{ fontSize: 8, color: colors.textLight }}>{children}</View>
        </View>
    );
};

const Section2Col = ({ title, children, width = "66%" }) => {
    return (
        <View style={{ width, marginTop: 6 }}>
            <Text style={{ fontSize: 10, fontWeight: "bold", marginBottom: 4 }}>
                {title}
            </Text>
            <View
                style={{
                    fontSize: 8,
                    color: colors.textLight,
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
            >
                {children}
            </View>
        </View>
    );
};

const Section3Col = ({ title, children, width = "100%" }) => {
    return (
        <View style={{ width, marginTop: 6 }}>
            <Text style={{ fontSize: 10, fontWeight: "bold", marginBottom: 4 }}>
                {title}
            </Text>
            <View
                style={{
                    fontSize: 8,
                    color: colors.textLight,
                    flexDirection: "row",
                    flexWrap: "wrap",
                }}
            >
                {children}
            </View>
        </View>
    );
};

const UnorderedList = ({ children, style = {} }) => {
    return <View style={style}>{children}</View>;
};

const ListItem = ({ children, style }) => {
    return <Text style={style}>{children}</Text>;
};

const CommunityManagerCard = ({ community }) => {
    let phoneNumber = "";
    let contactName = "";
    let contactTitle = "";
    let contactEmail = "";
    let contactPhoto = "";

    // if (community && community.contact[0].phone && community.contact[0].phone.length > 0) {
    //     phoneNumber = community.contact[0].phone[0].number;
    // }

    if (community && community.communityContact && community.communityContact.length > 0) {
        const communityContact = community.communityContact.find((c) => c.mainContact);
        if (communityContact) {
            contactName = communityContact.name;
            contactTitle = communityContact.contact[0].title;
            contactEmail = communityContact.contact[0].email;
            contactPhoto = communityContact.photoUrl;

            if (
                communityContact.contact[0].phone &&
                communityContact.contact[0].phone.length > 0
            ) {
                phoneNumber = formatPhoneNumber(
                    communityContact.contact[0].phone[0].number
                );
            }
        }
    }

    return (
        <View
            style={{
                width: "100%",
                marginTop: 16,
                padding: 8,
                backgroundColor: colors.bgLightGray,
                border: "1px solid #ccc",
            }}
        >
            <Text>Community Contact</Text>

            <View style={{ marginTop: 8, flexDirection: "row", alignItems: "center" }}>
                <View style={{ border: "2px solid #888", borderRadius: "100%" }}>
                    {contactPhoto ? (
                        <Image
                            src={contactPhoto}
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: "100%",
                            }}
                        />
                    ) : (
                        <View
                            style={{
                                width: 40,
                                height: 40,
                                borderRadius: "100%",
                                flexDirection: "row",
                                alignItems: "center",
                                justifyContent: "center",
                            }}
                        >
                            <Text
                                style={{ fontSize: 16, fontWeight: "bold", color: "#888" }}
                            >
                                {contactName
                                    .split(" ")
                                    .map((word) => word[0])
                                    .join("")
                                    .toUpperCase()}
                            </Text>
                        </View>
                    )}
                </View>

                <View style={{ marginLeft: 16 }}>
                    <Text style={{ fontSize: 10, fontWeight: "bold" }}>{contactName}</Text>
                    <Text style={{ fontSize: 8, color: "#666" }}>{contactTitle}</Text>
                    <Text style={{ marginTop: 8, fontSize: 8, color: "#222" }}>
                        {phoneNumber}
                    </Text>
                    <Text style={{ marginTop: 2, fontSize: 8, color: "#222" }}>
                        {contactEmail}
                    </Text>
                </View>
            </View>
        </View>
    );
};

const Notes = () => {
    const lines = Array(13).fill("0");

    return (
        <View style={{ marginTop: 16 }}>
            <Text style={{ fontSize: 12, fontWeight: "bold" }}>Notes</Text>

            <View>
                {lines.map((_, index) => (
                    <View
                        key={index}
                        style={{
                            width: "100%",
                            height: 25,
                            borderBottom: "1px solid #111",
                        }}
                    ></View>
                ))}
            </View>
        </View>
    );
};

const CommunityMls = ({ community, phone }) => {
    const [firstTime, setFirstTime] = useState(true);

    useEffect(() => {
        if (firstTime) {
            setFirstTime(false);
        }
    }, [firstTime]);

    const isClient = typeof window === "object";

    if (firstTime || !isClient) {
        return <div>MLS</div>;
    }

    console.log("community -", community);

    return (
        <div>
            {/*<h1>Test remove later</h1>*/}
            <PDFViewer className="w-full h-screen">
                {community.onboarded ? (
                    <OnboardedCommunity community={community} />
                ) : (
                    <NotOnboardedCommunity community={community} phone={phone} />
                )}
            </PDFViewer>
        </div>
    );
};

const sortUnits = (a, b) => {
    if (a.availability === "Available Now" && b.availability === "Available Now") {
        return 0;
    }

    if (a.availability === "Available Now") {
        return -1;
    }

    if (b.availability === "Available Now") {
        return 1;
    }

    if (a.availability === "Coming Soon" && b.availability === "Coming Soon") {
        if (a.startDate < b.startDate) {
            return -1;
        } else if (a.startDate > b.startDate) {
            return 1;
        } else {
            return 0;
        }
    }

    if (a.availability === "Coming Soon") {
        return -1;
    }

    if (b.availability === "Coming Soon") {
        return 1;
    }

    return 0;
};

const AvailableUnits = ({ community }) => {
    const paidPlan = community.plan && community.plan.startsWith("Paid");

    if (!paidPlan) {
        return null;
    }

    return (
        <View style={{ fontFamily: "Roboto" }}>
            <View
                wrap={false}
                style={{
                    margin: "8px 32px",
                    fontSize: 10,
                    fontFamily: "Roboto",
                    border: "1px solid #ccc",
                }}
            >
                <Text
                    style={{
                        padding: "4px 8px",
                        backgroundColor: colors.bgLightGray,
                        fontWeight: "black",
                    }}
                >
                    Available Units
                </Text>

                <View>
                    <View
                        style={{
                            padding: "4px 8px",
                            flexDirection: "row",
                            width: "100%",
                            borderTop: "1px solid #ccc",
                        }}
                    >
                        <Text style={{ fontWeight: "bold", width: "55%" }}>Floor Plan</Text>

                        <Text style={{ fontWeight: "bold", width: "15%" }}>Unit</Text>

                        <Text style={{ width: "15%" }}>Monthly Rent</Text>

                        <Text style={{ width: "15%" }}>Availability</Text>
                    </View>
                    {community.unit
                        ? community.unit
                              .sort(sortUnits)
                              .filter(
                                  (unit) =>
                                      unit.template_link &&
                                      (unit.availability === "Available Now" ||
                                          unit.availability === "Coming Soon")
                              )
                              .map((unit, index) => (
                                  <View key={index} style={{}} wrap={false}>
                                      <View
                                          style={{
                                              padding: "4px 8px",
                                              flexDirection: "row",
                                              width: "100%",
                                              borderTop: "1px solid #ccc",
                                          }}
                                      >
                                          <Text style={{ width: "55%" }}>
                                              {unit.template_link.name}
                                          </Text>

                                          <Text style={{ width: "15%" }}>{unit.name}</Text>

                                          <Text style={{ width: "15%" }}>
                                              {unit.monthlyRate
                                                  ? "$" + unit.monthlyRate
                                                  : ""}
                                          </Text>

                                          <Text style={{ width: "15%" }}>
                                              {checkUnitAvailability(unit) ===
                                              "Available Now"
                                                  ? "Now"
                                                  : checkUnitAvailability(unit) ===
                                                    "Coming Soon"
                                                  ? moment(unit.startDate).format(
                                                        "MMMM D, YYYY"
                                                    )
                                                  : ""}
                                          </Text>
                                      </View>
                                      <View
                                          style={{
                                              padding: "4px 8px",
                                          }}
                                      >
                                          {unit.specialOffer ? (
                                              <Text style={{ fontWeight: "bold" }}>
                                                  Special Incentive: {unit.specialOffer}*
                                              </Text>
                                          ) : null}

                                          {unit.description ? (
                                              <Text style={{}}>{unit.description}</Text>
                                          ) : null}

                                          {unit.descriptors ? (
                                              <View
                                                  style={{
                                                      flexDirection: "row",
                                                      flexWrap: "wrap",
                                                      marginTop: 4,
                                                  }}
                                              >
                                                  {unit.descriptors
                                                      .split(";")
                                                      .map((descriptor, index) => (
                                                          <View
                                                              key={descriptor}
                                                              style={{
                                                                  flexDirection: "row",
                                                              }}
                                                          >
                                                              {index > 0 ? (
                                                                  <Text>, </Text>
                                                              ) : null}
                                                              <Text>{descriptor}</Text>
                                                          </View>
                                                      ))}
                                              </View>
                                          ) : null}

                                          {unit.typeOfCare ? (
                                              <Text
                                                  style={{
                                                      flexDirection: "row",
                                                      flexWrap: "wrap",
                                                      marginTop: 4,
                                                      color: colors.primary,
                                                  }}
                                              >
                                                  {getCommunityTypesText(unit.typeOfCare)}
                                              </Text>
                                          ) : null}
                                      </View>
                                  </View>
                              ))
                        : null}
                </View>
            </View>

            {/*<View wrap={false}>
                <Text
                    style={{
                        margin: "4px 32px",
                        fontSize: 12,
                        fontWeight: "bold",
                    }}
                >
                    Deposit and Fees
                </Text>

                <View style={{ flexDirection: "col", fontSize: 10, margin: "4px 32px" }}>
                    {community.deposit ? (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginRight: 4 }}>&bull;</Text>
                            <Text>{community.deposit}</Text>
                        </View>
                    ) : null}

                    {community.careFee ? (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginRight: 4 }}>&bull;</Text>
                            <Text>{community.careFee}</Text>
                        </View>
                    ) : null}

                    {community.communityFee ? (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginRight: 4 }}>&bull;</Text>
                            <Text>{community.communityFee}</Text>
                        </View>
                    ) : null}

                    {community.secondPersonFee ? (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginRight: 4 }}>&bull;</Text>
                            <Text>{community.secondPersonFee}</Text>
                        </View>
                    ) : null}

                    {community.monthlyPetFee ? (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginRight: 4 }}>&bull;</Text>
                            <Text>{community.monthlyPetFee}</Text>
                        </View>
                    ) : null}

                    {community.petDeposit ? (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginRight: 4 }}>&bull;</Text>
                            <Text>{community.petDeposit}</Text>
                        </View>
                    ) : null}

                    {community.parkingFee ? (
                        <View style={{ flexDirection: "row", marginBottom: 4 }}>
                            <Text style={{ marginRight: 4 }}>&bull;</Text>
                            <Text>{community.parkingFee}</Text>
                        </View>
                    ) : null}
                </View>
            </View>*/}
        </View>
    );
};

export const toBlob = (community, phone) => {
    if (community.onboarded) {
        return pdf(OnboardedCommunity({ community })).toBlob();
    } else {
        return pdf(NotOnboardedCommunity({ community, phone })).toBlob();
    }
};

export default CommunityMls;
