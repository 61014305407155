import { Fragment } from "react";
import { Listbox, Transition } from "@headlessui/react";
import { CheckIcon, SelectorIcon } from "@heroicons/react/solid";

const Select = ({ value, onChange, options, className, label }) => {
    return (
        <div className={className}>
            <Listbox value={value} onChange={onChange}>
                <div className="flex items-center">
                    {label ? <Listbox.Label className="mr-4">{label}</Listbox.Label> : null}
                    <div className="relative flex-grow">
                        <Listbox.Button className="relative w-full py-2 pl-3 pr-10 text-left bg-white rounded-md border border-gray-300 cursor-default focus:outline-none focus-visible:ring-2 focus-visible:ring-opacity-75 focus-visible:ring-white focus-visible:ring-offset-orange-300 focus-visible:ring-offset-2 focus-visible:border-indigo-500 sm:text-sm">
                            <span className="block truncate">{value.name}</span>
                            <span className="absolute inset-y-0 right-0 flex items-center pr-2 pointer-events-none">
                                <SelectorIcon
                                    className="w-5 h-5 text-gray-400"
                                    aria-hidden="true"
                                />
                            </span>
                        </Listbox.Button>
                        <Transition
                            as={Fragment}
                            leave="transition ease-in duration-100"
                            leaveFrom="opacity-100"
                            leaveTo="opacity-0"
                        >
                            <Listbox.Options className="z-50 absolute w-full py-1 mt-1 overflow-auto text-base bg-white rounded-md shadow-lg max-h-60 ring-1 ring-black ring-opacity-5 focus:outline-none sm:text-sm">
                                {options.map((option, optionIdx) => (
                                    <Listbox.Option
                                        key={optionIdx}
                                        className={({ active }) =>
                                            `${
                                                active
                                                    ? "text-primary-900 bg-primary-100"
                                                    : "text-gray-900"
                                            }
                                        cursor-default select-none relative py-2 pl-10 pr-4`
                                        }
                                        value={option}
                                    >
                                        {({ selected, active }) => (
                                            <>
                                                <span
                                                    className={`${
                                                        value.value === option.value
                                                            ? "font-medium"
                                                            : "font-normal"
                                                    } block truncate`}
                                                >
                                                    {option.name}
                                                </span>
                                                {value.value === option.value ? (
                                                    <span
                                                        className={`${
                                                            active
                                                                ? "text-primary-500"
                                                                : "text-primary-600"
                                                        }
                                                        absolute inset-y-0 left-0 flex items-center pl-3`}
                                                    >
                                                        <CheckIcon
                                                            className="w-5 h-5"
                                                            aria-hidden="true"
                                                        />
                                                    </span>
                                                ) : null}
                                            </>
                                        )}
                                    </Listbox.Option>
                                ))}
                            </Listbox.Options>
                        </Transition>
                    </div>
                </div>
            </Listbox>
        </div>
    );
};

export default Select;
