import React, { useState, useEffect } from "react";
import moment from "moment";

import { XIcon } from "@heroicons/react/outline";

import { useFormik } from "formik";
import * as yup from "yup";

import Dialog from "@/ui/Dialog";
import TextFieldFormik from "@/ui/TextFieldFormik";
import { TextAreaFormik } from "@/ui/TextArea";
import Button from "@/ui/Button";
import { RadioGroupFormik } from "@/ui/RadioGroup";
import { readDocument, updateDocument } from "@/lib/db";
import { AuthProvider, useAuth } from "@/lib/auth";
import SignUpSignIn from "../user/SignUpSignIn";
import { IconButton } from "@/ui/IconButton";
import { YouNeedToRegister, getContactFromCommunity, getReceiveEmails } from "./utils";
import { trackButtonClick } from "../user/UserUtils";

const RsvpRequest = ({ community }) => {
    const [open, setOpen] = useState(false);

    return (
        <div>
            <button
                className={
                    "w-24 px-4 py-0.5 font-bold text-sm text-white bg-primary-500 rounded hover:bg-primary-400 "
                }
                onClick={() => {
                    const value = trackButtonClick("request_rsvp_button");
                    gtag("event", "request_rsvp", {
                        event_category: "engagement",
                        event_label: "request_rsvp_button",
                        value,
                        item_id: community.nameId,
                    });

                    const engagementCount = trackButtonClick("engagement");
                    gtag("event", "engagementCountUpdated", {
                        engagement_count: engagementCount,
                    });

                    setOpen(true);
                }}
            >
                RSVP
            </button>

            <RsvpRequestDialog community={community} open={open} setOpen={setOpen} />
        </div>
    );
};

export const RsvpRequestDialog = ({ community, open, setOpen }) => {
    const auth = useAuth();
    const [openSignUp, setOpenSignUp] = useState(false);
    const [openSignIn, setOpenSignIn] = useState(false);
    const [user, setUser] = useState({});
    const [complete, setComplete] = useState(false);
    const [email, setEmail] = useState("");

    const userId = auth && auth.user ? auth.user.uid : "";
    const token = auth && auth.user ? auth.user.token : "";

    const contact = getContactFromCommunity(community);
    const communityEmails = getReceiveEmails(community);

    useEffect(() => {
        const getUser = async (uid) => {
            const _user = await readDocument("users", uid);
            setUser(_user);
        };

        if (userId) {
            getUser(userId);
        }
    }, [userId]);

    const handleClose = () => {
        setOpen(false);
        setComplete(false);
    };

    return (
        <div>
            <Dialog
                open={open}
                className="fixed inset-0 z-50 overflow-y-auto"
                onClose={handleClose}
            >
                <div className="min-h-screen px-4 text-center">
                    <Dialog.Overlay className="fixed -z-10 inset-0 bg-black opacity-70" />

                    {/* This element is to trick the browser into centering the modal contents. */}
                    <span className="inline-block h-screen align-middle" aria-hidden="true">
                        &#8203;
                    </span>

                    <div
                        className={`inline-block w-full max-w-lg pb-8 overflow-hidden
                                            text-left align-middle transition-all transform 
                                            bg-white shadow-xl rounded-2xl`}
                    >
                        <div className="flex justify-end"></div>

                        <Dialog.Title className="px-4 py-2 flex justify-between items-center bg-primary-500">
                            <div className="flex justify-center flex-grow">
                                <h3 className="text-xl font-bold font-headline uppercase text-white">
                                    RSVP
                                </h3>
                            </div>
                            <IconButton onClick={handleClose}>
                                <XIcon className="w-6 h-6 text-white" />
                            </IconButton>
                        </Dialog.Title>

                        {userId === "" ? (
                            <YouNeedToRegister
                                setOpenSignIn={setOpenSignIn}
                                setOpenSignUp={setOpenSignUp}
                                onClose={handleClose}
                            />
                        ) : null}

                        {userId !== "" && !complete ? (
                            <RsvpRequestForm
                                community={community}
                                user={user}
                                onComplete={async (data) => {
                                    const res = await fetch(
                                        "/api/email/request-rsvp-claimed",
                                        {
                                            method: "post",
                                            headers: {
                                                "Content-Type": "application/json",
                                                token,
                                            },
                                            body: JSON.stringify(
                                                {
                                                    ...data,
                                                    title: `RSVP Request - ${community.name}`,
                                                    preview: `RSVP Request - ${community.name}`,
                                                    contact,
                                                    communityEmails,
                                                },
                                                null,
                                                2
                                            ),
                                        }
                                    );

                                    setComplete(true);
                                }}
                            />
                        ) : null}

                        {complete ? (
                            <RsvpRequestComplete community={community} email={email} />
                        ) : null}
                    </div>
                </div>
            </Dialog>

            <SignUpSignIn
                openSignUp={openSignUp}
                openSignIn={openSignIn}
                setOpenSignUp={setOpenSignUp}
                setOpenSignIn={setOpenSignIn}
            />
        </div>
    );
};

const validationSchema = yup.object({
    name: yup.string().required("Name is required").nullable(),
    email: yup.string().required("Email is required").nullable(),
    phone: yup.string().required("Mobile Number is required").nullable(),
    message: yup
        .string()
        .required("Describe the event you would like to attend, add any comments")
        .nullable(),
    eventDate: yup
        .date()
        .required("Date is required")
        .min(
            moment().add(1, "day").toDate(),
            "Date must start from " + moment().add(2, "day").format("MMM D")
        )
        .nullable(),
    numberOfPeople: yup.string().required("Number of people is required").nullable(),
    resident: yup.string().required("Select one option").nullable(),
});

const numberOfPeopleOptions = [
    { value: "1", name: "1" },
    { value: "2", name: "2" },
    { value: "3", name: "3" },
    { value: "4", name: "4" },
];

const RsvpRequestForm = ({ community, user, onComplete }) => {
    const [name, setName] = useState(user.name);
    const [phone, setPhone] = useState(user.phone);
    const [message, setMessage] = useState("");

    const formik = useFormik({
        initialValues: {
            name: user.name,
            email: user.email,
            phone: user.phone,
            eventDate: "",
            numberOfPeople: "",
            resident: "",
            message: "",
        },
        enableReinitialize: true,
        validationSchema: validationSchema,
        onSubmit: async (values) => {
            console.log("values", values);

            if (values.name !== user.name || values.phone !== user.phone) {
                await updateDocument("users", user.uid, {
                    ...user,
                    name: values.name ? values.name : user.anme,
                    phone: values.phone ? values.phone : user.phone,
                });
            }

            onComplete({
                name: values.name,
                email: values.email,
                phone: values.phone,
                eventDate: values.eventDate,
                numberOfPeople: values.numberOfPeople,
                resident: values.resident,
                message: values.message,
                communityName: community.name,
                communityUrl: community.nameId,
            });
        },
    });
    console.log("formik", formik.values);

    return (
        <form onSubmit={formik.handleSubmit}>
            <div className="mt-2 sm:mt-4 sm:mb-0 px-4 sm:px-16">
                <TextFieldFormik
                    formik={formik}
                    id="name"
                    name="name"
                    label="Full Name"
                    fullwidth
                    inputClassName="bg-gray-100"
                />

                <TextFieldFormik
                    formik={formik}
                    id="email"
                    type="email"
                    name="email"
                    label="Email"
                    fullwidth
                    disabled
                    className="mt-2"
                />

                <TextFieldFormik
                    formik={formik}
                    id="phone"
                    name="phone"
                    label="Mobile Number"
                    fullwidth
                    inputClassName="bg-gray-100"
                    className="mt-2"
                />

                <div className="grid grid-cols-2 space-x-4">
                    <TextFieldFormik
                        formik={formik}
                        type="date"
                        id="eventDate"
                        name="eventDate"
                        label="Event Date Requested"
                        fullwidth
                        inputClassName="bg-gray-100"
                        className="mt-2"
                        helperText={
                            "Starting from " + moment().add(2, "day").format("MMM D")
                        }
                    />

                    <TextFieldFormik
                        formik={formik}
                        select
                        id="numberOfPeople"
                        name="numberOfPeople"
                        label="Number of People"
                        fullwidth
                        inputClassName="bg-gray-100"
                        className="mt-2"
                        options={numberOfPeopleOptions}
                    />
                </div>

                <div className="grid grid-cols-2 space-x-4">
                    <RadioGroupFormik
                        formik={formik}
                        id="resident"
                        name="resident"
                        label="Resident"
                        className="mt-2 space-y-2"
                        required
                        options={[
                            { value: "I am the Resident", name: "I am the Resident" },
                            {
                                value: "I am Helping a Resident",
                                name: "I am Helping a Resident",
                            },
                        ]}
                    />
                </div>

                <TextAreaFormik
                    formik={formik}
                    id="message"
                    name="message"
                    label="Describe the event you would like to attend, add any comments"
                    fullwidth
                    inputClassName="bg-gray-100"
                    className="mt-2"
                    textareaClassName="bg-gray-100"
                />

                <div className="mt-2 flex justify-between">
                    <Button className="uppercase" type="submit">
                        Submit RSVP
                    </Button>
                </div>
            </div>
        </form>
    );
};

const RsvpRequestComplete = ({ community, email }) => {
    return (
        <div className="mt-6 mb-4 px-16">
            <p className="text-lg text-center">Thank you for requesting an RSVP at</p>

            <p className="mt-2 text-xl text-primary-500 font-bold text-center">
                {community.name}
            </p>

            <p className="mt-4 text-center">
                Your RSVP request has been sent to this community.
            </p>

            <hr className="mt-4 mx-24 border-black" />

            <p className="mt-8 text-sm font-bold text-center">
                If you do not receive a confirmation from the community within 24 hours,
                please reach out to them directly.
            </p>

            <p className="mt-4 text-sm font-bold text-center">
                Remember! Tell them Purple Door Finders sent you!
            </p>
        </div>
    );
};

export default RsvpRequest;
