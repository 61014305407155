import React, { useState } from "react";
import { useRouter } from "next/router";
import Link from "@/utils/link";

import { PencilIcon } from "@heroicons/react/outline";
import { FaTwitter, FaLinkedinIn, FaFacebookF, FaInstagram } from "react-icons/fa";

import { IconButton } from "../ui/Button";
import Tooltip from "@/ui/Tooltip";
import { host } from "@/utils/config";
import AskQuestion from "../dialogs/AskQuestion";
import TourRequest from "../dialogs/TourRequest";
import RequestInfo from "../dialogs/RequestInfo";
import { removeExt, PdImage } from "@/utils/image";

export const CommunityMlsCard = ({ community }) => {
    const router = useRouter();

    const handleClick = () => {
        //router.push("/community-mls/" + community.nameId);
        window.open("/community-mls/" + community.nameId, "_blank");
    };

    return (
        <div className="border border-gray-200 rounded shadow overflow-hidden">
            <Title>Community PDF Listing</Title>

            <div className="bg-gray-50 py-4 text-center">
                <DarkButton className="w-[220px]" onClick={handleClick}>
                    Get Community PDF Listing
                </DarkButton>
            </div>
        </div>
    );
};

export const CommunitySocialButtons = ({ community }) => {
    return (
        <div className="mt-0 flex justify-center space-x-4">
            {community.instagramUrl ? (
                <Link href={community.instagramUrl}>
                    <a className="p-2 bg-primary-500 rounded-full hover:bg-primary-400">
                        <FaInstagram className="w-6 h-6 text-white" />
                    </a>
                </Link>
            ) : null}

            {community.facebookUrl ? (
                <Link href={community.facebookUrl}>
                    <a className="p-2 bg-primary-500 rounded-full hover:bg-primary-400">
                        <FaFacebookF className="w-6 h-6 text-white" />
                    </a>
                </Link>
            ) : null}

            {community.twitterUrl ? (
                <Link href={community.twitterUrl}>
                    <a className="p-2 bg-primary-500 rounded-full hover:bg-primary-400">
                        <FaTwitter className="w-6 h-6 text-white" />
                    </a>
                </Link>
            ) : null}

            {community.linkedInUrl ? (
                <Link href={community.linkedInUrl}>
                    <a className="p-2 bg-primary-500 rounded-full hover:bg-primary-400">
                        <FaLinkedinIn className="w-6 h-6 text-white" />
                    </a>
                </Link>
            ) : null}
        </div>
    );
};

export const ContactCommunityCard = ({ community }) => {
    let phoneNumber = "";

    if (community && community.contact[0].phone && community.contact[0].phone.length > 0) {
        phoneNumber = community.contact[0].phone[0].number;
    }

    return (
        <div className="h-64 border border-gray-200 rounded shadow overflow-hidden">
            <Title>Contact Community</Title>

            <div className="h-full pt-8 px-8 bg-gray-50">
                <div className="xl:flex xl:space-x-2">
                    <DarkButton>Request Tour</DarkButton>
                    <DarkButton>Send Message</DarkButton>
                </div>

                {phoneNumber ? (
                    <div className="mt-8 text-center">Or Call Direct - {phoneNumber}</div>
                ) : null}
            </div>
        </div>
    );
};

export const BookYourTourCard = ({ community }) => {
    // let phoneNumber = "";

    // if (community && community.contact[0].phone && community.contact[0].phone.length > 0) {
    //     phoneNumber = community.contact[0].phone[0].number;
    // }

    return (
        <div className="border border-gray-200 rounded shadow overflow-hidden">
            {/*<Title>Request a Tour</Title>*/}

            <div className="bg-gray-50">
                <div className="pt-2 px-6 text-center text-sm font-bold text-primary-500">
                    {community ? community.name : ""}
                </div>

                <div className="mt-4 pb-4 flex justify-center">
                    <div>
                        <TourRequest community={community} />

                        {/*phoneNumber ? (
                            <div className="mt-4 text-center">
                                Or Call Direct - {phoneNumber}
                            </div>
                        ) : null*/}
                    </div>
                </div>
            </div>
        </div>
    );
};

const Day = ({ date }) => {
    return (
        <div className="w-20 h-20 pt-2 bg-white border border-gray-900 rounded">
            <div className="text-xs text-center uppercase text-gray-500">
                {date.format("ddd")}
            </div>
            <div className="mt-0.5 text-xl text-center">{date.format("DD")}</div>
            <div className="mt-0.5 text-xs text-center text-gray-500 uppercase">
                {date.format("MMM")}
            </div>
        </div>
    );
};

export const AskQuestionCard = ({ community, step, onEdit, enableEdit }) => {
    //let phoneNumber = "";
    let contactName = "";
    let contactTitle = "";
    let contactPhoto = "";

    /*if (community && community.contact[0].phone && community.contact[0].phone.length > 0) {
        phoneNumber = community.contact[0].phone[0].number;
    }*/

    if (community && community.communityContact && community.communityContact.length > 0) {
        const communityContact = community.communityContact.find((c) => c.mainContact);
        if (communityContact) {
            contactName = communityContact.contact[0].name;
            contactTitle = communityContact.contact[0].title;
            contactPhoto = communityContact.photoUrl;
        }
    }

    if (contactName) {
        return (
            <div className="border bg-gray-50 border-gray-200 rounded shadow overflow-hidden">
                <div className="flex justify-around items-center">
                    <p className="py-2 text-sm text-gray-700 text-center">
                        Connect Directly to the Community Manager
                    </p>

                    {enableEdit ? <EditButton step={step} onEdit={onEdit} /> : null}
                </div>

                <div className="pt-2 pb-4 flex sm:flex-col sm:items-center lg:flex-row justify-center">
                    {contactPhoto ? (
                        <div className="relative h-20 w-20">
                            <PdImage
                                src={removeExt(contactPhoto) + ".th.jpg"}
                                alt="Contact Photo"
                                className="h-20 w-20 rounded-full ring-2 ring-gray-500"
                            />
                        </div>
                    ) : (
                        <div className="flex items-center justify-center h-20 w-20 text-3xl font-bold text-gray-500 rounded-full ring-2 ring-gray-500">
                            {contactName
                                .split(" ")
                                .map((word) => word[0])
                                .join("")
                                .toUpperCase()}
                        </div>
                    )}

                    <div className="ml-4">
                        <div className="mb-2">
                            <div className="font-bold">{contactName}</div>
                            <div className="text-sm text-gray-700">{contactTitle}</div>
                        </div>
                        <AskQuestion community={community} />
                    </div>
                </div>
            </div>
        );
    } else {
        return null;
    }
};

const EditButton = ({ step, onEdit, className }) => {
    return (
        <Tooltip title="Edit" placement="bottom">
            <IconButton
                onClick={() => onEdit(step)}
                className={className}
                data-tippy-content="Tooltip placement top"
                data-tippy-placement="top"
            >
                <PencilIcon className="w-6 h-6 text-primary-500" />
            </IconButton>
        </Tooltip>
    );
};

const Title = ({ children }) => {
    return (
        <h3 className="py-2 text-center font-semibold bg-gray-100 border-b border-gray-300">
            {children}
        </h3>
    );
};

export const DarkButton = ({ children, onClick, className = "" }) => {
    return (
        <button
            onClick={onClick}
            className={
                "w-36 px-4 py-2 font-bold text-sm text-white bg-primary-500 border-2 border-primary-500 rounded hover:bg-primary-400 " +
                className
            }
        >
            {children}
        </button>
    );
};

export const LightButton = ({ children, onClick, className = "" }) => {
    return (
        <button
            onClick={onClick}
            className={
                "w-36 px-4 py-2 font-bold text-sm text-primary-500 bg-white border-2 border-primary-500 rounded hover:bg-primary-100 " +
                className
            }
        >
            {children}
        </button>
    );
};

export const RequestInfoCard = ({ community, phone, email }) => {
    return (
        <div className="border border-gray-200 rounded shadow overflow-hidden">
            <Title>Request Info</Title>

            <div className="mt-2 mb-4 h-full px-8 bg-gray-50 text-sm">
                <p>Request Information about this community</p>

                <div className="mt-4 flex justify-center">
                    <RequestInfo community={community} phone={phone} email={email} />
                </div>
            </div>
        </div>
    );
};

export const UpdateCommunityCard = ({ community }) => {
    const router = useRouter();

    const handleUpdateCommunity = () => {
        // store the name and address in local storage
        const c = {
            nameId: community.nameId,
            name: community.name,
            contact: community.contact,
        };

        router.push(host + "community-portal");
    };

    return (
        <div className="h-32 border border-gray-200 rounded shadow overflow-hidden">
            <div className="mt-2 h-full px-8 bg-gray-50 text-sm">
                <p>I manage this community and would like to update this profile.</p>

                <div className="mt-4 flex justify-center">
                    <DarkButton className="w-52" onClick={handleUpdateCommunity}>
                        Update Community
                    </DarkButton>
                </div>
            </div>
        </div>
    );
};
