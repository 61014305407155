import Button from "@/ui/Button";

export const YouNeedToRegister = ({ setOpenSignIn, setOpenSignUp, onClose }) => {
    return (
        <div className="mt-4 px-8">
            <p className="text-lg">
                The community will need your contact information in order to answer your
                question. Please create an account here. You will also be able to favorite
                communities and save resource documents in your profile.
            </p>

            <div className="mt-8 flex items-center">
                <Button
                    className=""
                    onClick={() => {
                        if (onClose) {
                            onClose();
                        }
                        setOpenSignUp(true);

                        gtag("event", "conversion", {
                            send_to: "AW-10778046073/z0kvCMDQspMaEPncr5Mo",
                        });
                    }}
                >
                    Create Account
                </Button>
            </div>

            <div className="mt-4">
                Already have an account?
                <buton
                    className="ml-4 text-purple-500 hover:underline"
                    onClick={() => {
                        if (onClose) {
                            onClose();
                        }
                        setOpenSignIn(true);
                    }}
                >
                    Sign In
                </buton>
            </div>
        </div>
    );
};

export const getContactFromCommunity = (community) => {
    let contact = {
        name: "",
        email: "",
        phone: "",
    };

    if (community.onboarded) {
        // take the first main contact, or the first contact
        if (community.communityContact && community.communityContact.length > 0) {
            let communityContact = community.communityContact[0];
            let mainContact = community.communityContact.find(
                (c) => c.mainContact === true
            );
            if (mainContact) {
                contact = mainContact;
            }

            if (communityContact.name) {
                contact.name = communityContact.name;
            }

            if (communityContact.contact[0].email) {
                contact.email = communityContact.contact[0].email;
            }

            if (
                communityContact.contact[0].phone &&
                communityContact.contact[0].phone.length > 0
            ) {
                contact.phone = communityContact.contact[0].phone[0].number;
            }
        }
    } else {
        contact.name = "Unclaimed Community";
    }

    return contact;
};

export const getReceiveEmails = (community) => {
    let emails = [];

    if (community.onboarded) {
        // get a list of all contacts with received_email_notifications set to true
        if (community.communityContact && community.communityContact.length > 0) {
            community.communityContact.forEach((c) => {
                if (c.receiveEmails) {
                    emails.push(c.contact[0].email);
                }
            });
        }
    }

    return emails;
};
