import React, { useState, useEffect } from "react";

import { readDocument, updateDocument } from "@/lib/db";
import { useAuth } from "@/lib/auth";
import { getContactFromCommunity, getReceiveEmails } from "./utils";

const checkCommunityInLocalStorage = (communityNameId) => {
    const communities = JSON.parse(localStorage.getItem("communities")) || [];
    return communities.includes(communityNameId);
};

const addCommunityToLocalStorage = (communityNameId) => {
    const communities = JSON.parse(localStorage.getItem("communities")) || [];
    communities.push(communityNameId);
    localStorage.setItem("communities", JSON.stringify(communities));
};

const UserVisitCommunity = ({ community, enable, disableEmail }) => {
    if (!enable) {
        return null;
    }

    const auth = useAuth();

    const loadingUser = auth.loading;
    const uid = auth.user ? auth.user.uid : "";
    const token = auth.user ? auth.user.token : "";

    const contact = getContactFromCommunity(community);
    const communityEmails = getReceiveEmails(community);
    const communityNameId = community.nameId;

    useEffect(() => {
        const process = async (_uid, _token, _gaUserId) => {
            let _user;
            if (_uid) {
                _user = await readDocument("users", _uid);
            }

            // send email to community
            const res = await fetch("/api/email/user-visit-community", {
                method: "post",
                headers: {
                    "Content-Type": "application/json",
                    token: _token,
                },
                body: JSON.stringify(
                    {
                        communityName: community.name,
                        communityNameId,
                        plan: community.plan,
                        contact,
                        communityEmails,
                        user: _user ? _user : {},
                        gaUserId: _gaUserId,
                        disableEmail,
                        name: localStorage.getItem("name"),
                    },
                    null,
                    2
                ),
            });

            // mark the community as visited
            if (res.ok) {
                addCommunityToLocalStorage(communityNameId);
            }
        };

        const visited = checkCommunityInLocalStorage(communityNameId);

        // get the google analytics user id
        const gaUserId = localStorage.getItem("userId");

        if (!visited && !loadingUser && communityNameId) {
            process(uid, token, gaUserId);
        }
    }, [loadingUser, communityNameId, uid, token]);

    return null;
};

export default UserVisitCommunity;
